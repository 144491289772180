import React, { useMemo } from 'react'
import { useAppSelector } from 'src/store'
import { resizeImage } from 'src/utils/image'
import { formattedPrice } from 'src/utils/price'
import { useDispatch } from 'react-redux'
import { trackEvent } from 'src/utils/tracking'
import { HandleLookRequest } from 'src/store/actions/look'
import { ChangeShowFiltersAction } from 'src/store/actions/filters'
import { Button, Col, Image, Typography } from 'antd'
import { hideSwipe } from 'src/store/slices/layoutSlice'
import { getColorIcon } from 'src/utils/icon'
import { addVariantFilter } from 'src/utils/variantHelpers'
import { getCurrentActiveFiltersForType } from 'src/store/slices/databaseSlice'
import { CATALOG } from 'src/settings/global'
import StartingAt from '../StartingAt'
import useGarmentMethodsHook from 'src/utils/garmentMethodsHook'

const { Paragraph } = Typography

interface CardItemHorizontalProps {
    data: Models.Garment
}

export default function CardItemHorizontal(props: CardItemHorizontalProps) {
    const { data } = props

    const dispatch = useDispatch()
    const { convertedPrice } = useGarmentMethodsHook(data)

    const company = useAppSelector((state) => state.profile?.company)
    const internal = useAppSelector((state) => state.profile?.company?.internal)
    const useClipping = useAppSelector((state) => state.profile?.company?.use_clipping)
    const priceFloat = useAppSelector((state) => state.profile?.company?.price_float)
    const garmentType = useAppSelector((state) => state.garment?.type)
    const activeFilters = useAppSelector((state) =>
        getCurrentActiveFiltersForType(state, garmentType)
    )
    const garment = useAppSelector((state) =>
        state.look.request ? state.look.request[data.garment_type.toLowerCase()] : null
    )

    const activeVariantValues = activeFilters['garment_variant_ids']

    const imageUrl =
        useClipping && data.image_clipping_url ? data.image_clipping_url : data.image_url

    const isSelected = garment?.garment_id === data?.garment_id

    const garmentPrice = convertedPrice?.product_price || data.product_price
    const garmentOriginalPrice =
        convertedPrice?.product_price_original || data.product_price_original
    const garmentCurrency = convertedPrice?.product_currency || data.product_currency

    const isPromotion = useMemo(() => {
        if (data) {
            return !!garmentOriginalPrice && garmentOriginalPrice > garmentPrice
        }

        return false
    }, [data, garmentPrice, garmentOriginalPrice])

    const handleCardClick = () => {
        trackEvent('Item Selected', [data, { catalog_mode: 'horizontal' }], 'Catalog')
        dispatch(
            HandleLookRequest({
                lookRequest: {
                    [data.garment_type.toLowerCase()]: data,
                },
                keepAdditional: true,
                focus: data.garment_type,
                from: CATALOG,
            })
        )
        dispatch(ChangeShowFiltersAction(false))
        dispatch(hideSwipe())
    }

    const handleColorClick = () => {
        addVariantFilter(data.garment_id)
    }

    const isCardEmpty = !((company.name_horizontal_catalog && data.product_name) || garmentPrice)

    return (
        <div
            className={`card-item-horizontal--container${
                company.name_horizontal_catalog ? '-with-name' : ''
            }${isCardEmpty ? ' card-item-horizontal--container-empty' : ''}${
                isSelected ? ' card-item-horizontal--container-selected' : ''
            }`}
            onClick={handleCardClick}
            id={data.garment_id}
        >
            {data.garment_has_variants && !activeVariantValues && (
                <Button
                    className='button--icon card-item-horizontal--button-color'
                    icon={getColorIcon()}
                    onClick={handleColorClick}
                />
            )}
            <Image
                className='card-item-horizontal--image'
                src={resizeImage(imageUrl, { width: 800 })}
                preview={false}
                draggable={false}
            />
            {((company.name_horizontal_catalog && data.product_name) || data.product_price) && (
                <Col
                    className='card-item-horizontal--body'
                    style={{
                        justifyContent: company.name_horizontal_catalog
                            ? 'space-between'
                            : undefined,
                    }}
                >
                    <Col className='card-item-horizontal--price-container'>
                        <StartingAt garment={data} style={{ fontSize: 10, textAlign: 'center' }} />
                        {isPromotion && (
                            <div className='card-item-horizontal--promotion-container'>
                                <span className='card-item-horizontal--price--original'>
                                    {convertedPrice && convertedPrice.product_price_original_string
                                        ? convertedPrice.product_price_original_string
                                        : formattedPrice(
                                              garmentOriginalPrice,
                                              garmentCurrency,
                                              priceFloat
                                          )}
                                </span>
                                {internal === 'gemofr' ? (
                                    <span className='card-item-horizontal--price--percent'>
                                        PROMO
                                    </span>
                                ) : (
                                    <span className='card-item-horizontal--price--percent'>
                                        -
                                        {Math.round(
                                            ((garmentOriginalPrice - garmentPrice) * 100) /
                                                garmentOriginalPrice
                                        )}
                                        %
                                    </span>
                                )}
                            </div>
                        )}

                        <div
                            className={`card-item-horizontal--price--current${
                                isPromotion
                                    ? ' card-item-horizontal--price--current--promotion'
                                    : ''
                            }`}
                        >
                            {convertedPrice && convertedPrice.product_price_string
                                ? convertedPrice.product_price_string
                                : formattedPrice(garmentPrice, garmentCurrency, priceFloat)}
                        </div>
                    </Col>

                    {company.name_horizontal_catalog && data.product_name && (
                        <Paragraph
                            className='card-item-horizontal--product-name'
                            ellipsis={{ rows: 2 }}
                        >
                            {data.product_name}
                        </Paragraph>
                    )}
                </Col>
            )}
        </div>
    )
}
