import { StringMap, TOptions } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default function useCustomTranslation() {
    const { t } = useTranslation()
    const internal = useSelector((state: State.Root) => state.profile?.company?.internal)
    const experienceId = useSelector((state: State.Root) => state.profile?.company?.experience_id)

    function translateWithOverride(label: string, option?: string | TOptions<StringMap>) {
        const labelArray = [
            experienceId && internal ? `${internal}.${experienceId}.${label}` : null,
            experienceId ? `${experienceId}.${label}` : null,
            internal ? `${internal}.${label}` : null,
            label,
        ].filter((value) => value !== null)

        return t(labelArray, option)
    }

    return { t: translateWithOverride }
}
