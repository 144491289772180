import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Slider from 'react-slick'

import CarouselCard from 'src/components/card/cardCarouselGarment'

import { trackEvent } from 'src/utils/tracking'
import { shouldHide } from 'src/utils/lazyLoadCarousel'
import {
    getAllGarmentsObjectForType,
    setInsertionIndexForType,
} from 'src/store/slices/databaseSlice'
import { useAppSelector } from 'src/store'
import useCustomGetGarments from 'src/utils/custom-getGarments-hook'
import { HandleLookRequest } from 'src/store/actions/look'
import { FetchTypeGarmentAction } from 'src/store/actions/garment'
import { closeDrawer } from 'src/store/slices/layoutSlice'
import { getQueryValue } from 'src/utils/query'
import { handleSlickListOffset } from 'src/utils/carousel'
import { getLeftIcon, getRightIcon } from 'src/utils/icon'
import { SWIPE } from 'src/settings/global'

interface CarouselGarmentProps {
    type: string
    cardHeight?: number
}

const CarouselGarment: React.FunctionComponent<CarouselGarmentProps> = (props) => {
    const { type, cardHeight } = props
    const dispatch = useDispatch()
    const garmentIndexOffset = window.innerWidth < 1200 ? 1 : 2

    const look = useSelector((state: State.Root) => state.look)

    const showFilters = useAppSelector((state) => state.filters.showFilters)
    const company = useAppSelector((state) => state.profile.company)

    const currentGarment = useAppSelector((state) =>
        state.look.request ? state.look.request[type.toLowerCase()] : null
    )
    const allGarments = useAppSelector((state) => getAllGarmentsObjectForType(state, type))
    const databaseAllGarments = useAppSelector((state) => state.databaseSlice.allGarments)
    const garmentType = useSelector((state: State.Root) => state.garment?.type)
    const layoutSlice = useAppSelector((state) => state.layoutSlice)
    const [getGarmentsTrigger, { isLoading }] = useCustomGetGarments()

    const seenGarment = useRef<Models.Garment[]>([])
    const swipeDirection = useRef<string>()
    const seenMargins = useRef<{ right: number; left: number }>() // Values used to insert new data in the correct position

    const sliderRef = useRef(null)

    const lowerCaseType = type.toLowerCase()

    const initIndex = allGarments.all.findIndex(
        (garment) =>
            !look.request[lowerCaseType] ||
            look.request[lowerCaseType].garment_id === garment.garment_id
    )

    const initialSlide = useRef<number>(initIndex !== -1 ? initIndex : 0)

    const [currentSlide, setCurrentSlide] = useState(initialSlide.current)
    const swipeTrackingEvent: any = useRef()

    const [key, setKey] = useState<number>(0)

    let augmentedData = []

    const updateSeenMargins = (current: number) => {
        // We set the initial value of the current Margin
        let currentRightMargin = current + garmentIndexOffset + 1
        let currentLeftMargin = current - garmentIndexOffset

        // If the left margin is negative that means we are on the other side of the array
        if (currentLeftMargin < 0) {
            currentLeftMargin += augmentedData.length
        }
        // If the right margin is bigger than the data length that means we are on the other side of the array
        if (currentRightMargin > augmentedData.length) {
            currentRightMargin = currentRightMargin - augmentedData.length
        }

        // Init values
        if (swipeDirection === undefined || seenMargins.current === undefined) {
            return (seenMargins.current = { right: currentRightMargin, left: currentLeftMargin })
        }

        // If we swipe on the left side we update the right margin if necessary
        if (
            (currentRightMargin > seenMargins.current.right && swipeDirection.current === 'left') ||
            !seenMargins.current.right
        ) {
            seenMargins.current.right = currentRightMargin
        }

        // If we swipe on the right side we update the left margin if necessary
        if (
            (currentLeftMargin < seenMargins.current.left && swipeDirection.current === 'right') ||
            !seenMargins.current.left
        ) {
            seenMargins.current.left = currentLeftMargin
        }
    }

    const updateSeenGarment = (current: number) => {
        for (let i = current - garmentIndexOffset; i <= current + garmentIndexOffset; i++) {
            let realIndex = i

            if (i < 0) {
                realIndex = augmentedData.length + i
            }

            if (i >= augmentedData.length) {
                realIndex = i - augmentedData.length
            }

            if (
                augmentedData[realIndex] !== undefined &&
                seenGarment.current.findIndex(
                    (garment) => garment.garment_id === augmentedData[realIndex].garment_id
                ) === -1
            ) {
                seenGarment.current.push(augmentedData[realIndex])
            }
        }
    }

    useEffect(() => {
        updateSeenMargins(currentSlide)
        updateSeenGarment(currentSlide)

        const swipeElement = document.getElementsByClassName(`carousel-swipe-${type}`)

        if (!swipeElement || swipeElement.length === 0) {
            return undefined
        }

        // ---- Observe Swipe Element for resize calls ----
        const resizeObserver = new ResizeObserver(() => {
            handleSlickListOffset(type)
        })
        resizeObserver.observe(swipeElement[0])

        return () => {
            resizeObserver.disconnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // Called only when we are changing the look from the modal post add to cart
        if (
            currentGarment &&
            augmentedData[currentSlide] &&
            !showFilters &&
            augmentedData[currentSlide].garment_id !== currentGarment.garment_id
        ) {
            const foundIndex = augmentedData.findIndex(
                (garment) => garment.garment_id === currentGarment.garment_id
            )

            // If we don't find the new garment in the current data we fetch new data
            if (foundIndex === -1) {
                return getGarmentsTrigger({
                    garment_id: currentGarment.garment_id,
                    type: type,
                    page: 1,
                })
            }

            // We reset the seenGarment and seenMargins and go to the new garment in the carousel
            seenGarment.current = []
            seenMargins.current = undefined
            return sliderRef.current.slickGoTo(foundIndex, true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGarment])

    useEffect(() => {
        if (
            currentGarment &&
            (!augmentedData[currentSlide] ||
                augmentedData[currentSlide].garment_id !== currentGarment.garment_id)
        ) {
            const foundIndex = augmentedData.findIndex(
                (garment) => garment.garment_id === currentGarment.garment_id
            )
            setKey(key + 1)
            updateSeenMargins(foundIndex)
            updateSeenGarment(foundIndex)
            setCurrentSlide(foundIndex)
            initialSlide.current = foundIndex
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allGarments.all, currentGarment])

    // We look for the data-index attribute on the elem and then on the parent until we find it
    const findDataIndexValue = (elem: Element) => {
        if (!elem.attributes['data-index']) {
            if (!elem.parentElement) {
                return null
            }

            return findDataIndexValue(elem.parentElement)
        }

        return elem.attributes['data-index'].value
    }

    if (!allGarments.all || !allGarments.all.length) {
        return <div className='model--empty'></div>
    }

    const toFlat = [allGarments.all]
    for (let i = allGarments.all.length; i <= 6; i += allGarments.all.length) {
        toFlat.push(allGarments.all)
    }
    augmentedData = toFlat.flat()

    const CarouselNextArrow = (arrowProps) => {
        const { className, onClick } = arrowProps

        const handleClick = (e) => {
            swipeDirection.current = 'left'
            swipeTrackingEvent.current = {
                name: `Swipe right`,
                properties: { swipe_type: type, swipe_from: 'arrow' },
                category: 'Swipe',
            }
            onClick(e)
        }

        return (
            <div
                className={`${className} carousel-swipe--arrow carousel-swipe--right`}
                onClick={handleClick}
            >
                {getRightIcon()}
            </div>
        )
    }

    const CarouselPrevArrow = (arrowProps) => {
        const { className, onClick } = arrowProps

        const handleClick = (e) => {
            swipeDirection.current = 'right'
            swipeTrackingEvent.current = {
                name: `Swipe left`,
                properties: { swipe_type: type, swipe_from: 'arrow' },
                category: 'Swipe',
            }
            onClick(e)
        }

        return (
            <div
                className={`${className} carousel-swipe--arrow carousel-swipe--left`}
                onClick={handleClick}
            >
                {getLeftIcon()}
            </div>
        )
    }

    const handleBeforeChange = (next) => {
        // ---- Close drawer if it's shown ----
        if (layoutSlice.drawer === 'small' && window.innerWidth / window.innerHeight < 1) {
            dispatch(closeDrawer())
        }

        updateSeenMargins(next)
        updateSeenGarment(next)

        setCurrentSlide(next)

        // Special condition for client Jules. To be able to see the TOP that we change we remove the sweater if present with an outerwear
        const removeSweater =
            company.internal.match(/^jules/) &&
            look.request.sweater &&
            look.request.outerwear &&
            type === 'TOP'

        // Get preload ids by types
        const preLoadTypeIds = {}
        preLoadTypeIds[type.toLowerCase()] = [
            augmentedData[next ? next - 1 : augmentedData.length - 1].garment_id,
            augmentedData[next >= augmentedData.length - 1 ? 0 : next + 1].garment_id,
        ]
        if (['TOP', 'BOTTOM'].includes(type)) {
            const oppositeType = type === 'TOP' ? 'BOTTOM' : 'TOP'
            const databaseAll = databaseAllGarments[oppositeType].all
            const foundIndex = databaseAll.findIndex(
                (databaseGarment) =>
                    databaseGarment.garment_id ===
                    look.request[oppositeType.toLowerCase()].garment_id
            )

            // Only when theres an index
            if (foundIndex !== -1) {
                preLoadTypeIds[oppositeType.toLowerCase()] = [
                    databaseAll[foundIndex ? foundIndex - 1 : databaseAll.length - 1].garment_id,
                    databaseAll[foundIndex >= databaseAll.length - 1 ? 0 : foundIndex + 1]
                        .garment_id,
                ]
            }
        }

        dispatch(
            HandleLookRequest({
                lookRequest: {
                    [type.toLowerCase()]: augmentedData[next],
                    ...(removeSweater ? { sweater: null } : null),
                },
                preLoadTypeIds,
                keepAdditional: true,
                focus: type,
                from: SWIPE,
            })
        )
        if (garmentType !== type) {
            dispatch(FetchTypeGarmentAction(type))
        }
    }

    const handleAfterChange = (next) => {
        const lastQueryResult = allGarments.current
        if (
            seenGarment.current.length > augmentedData.length - 5 &&
            lastQueryResult.current_page_number * lastQueryResult.num_items_per_page <
                lastQueryResult.total_count &&
            !isLoading
        ) {
            dispatch(
                setInsertionIndexForType({
                    type,
                    index:
                        swipeDirection.current === 'right'
                            ? seenMargins.current.right
                            : seenMargins.current.left,
                })
            )
            getGarmentsTrigger({
                ...(allGarments.filterGarmentId && { garment_id: allGarments.filterGarmentId }),
                type: type,
                page: lastQueryResult.current_page_number + 1,
            })
            seenMargins.current.left +=
                lastQueryResult.current_page_number * lastQueryResult.num_items_per_page
        }

        const garment = augmentedData[next]
        trackEvent('Item Selected', garment, 'Swipe')
    }

    const handleGarmentClick = (e: React.MouseEvent) => {
        const dataIndex = findDataIndexValue(e.currentTarget)
        swipeDirection.current = dataIndex < currentSlide ? 'right' : 'left'
        dataIndex && sliderRef.current.slickGoTo(dataIndex)
        trackEvent('Item card Clicked', [augmentedData[dataIndex]], 'Swipe')
    }

    const maxSlidesToShow = !getQueryValue('hide_cart') || layoutSlice.leftPart === 'medium' ? 3 : 5

    const settings = {
        initialSlide: initialSlide.current,
        className: 'center',
        centerMode: true,
        infinite: true,
        centerPadding: '0px',
        slidesToShow: maxSlidesToShow,
        touchThreshold: 15,
        swipeToSlide: true,
        speed: 200,
        arrow: true,
        nextArrow: <CarouselNextArrow />,
        prevArrow: <CarouselPrevArrow />,
        onSwipe: (direction) => {
            swipeDirection.current = direction
            const adaptedDirection = direction == 'right' ? 'left' : 'right'
            swipeTrackingEvent.current = {
                name: `Swipe ${adaptedDirection}`,
                properties: { swipe_type: type, swipe_from: 'image' },
                category: 'Swipe',
            }
        },
        beforeChange: (current, next) => {
            if (swipeTrackingEvent.current) {
                // ---- Oblige de faire comme ca car beforeChange se lance apres le onSwipe ----
                trackEvent(
                    swipeTrackingEvent.current.name,
                    [augmentedData[next], swipeTrackingEvent.current.properties],
                    swipeTrackingEvent.current.category
                )
                swipeTrackingEvent.current = null
            }
            handleBeforeChange(next)
        },
        afterChange: (newCurrent) => {
            handleAfterChange(newCurrent)
        },
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: maxSlidesToShow,
                },
            },
        ],
    }
    return (
        <>
            <Slider
                {...settings}
                className={`carousel-swipe carousel-swipe--container carousel-swipe-${type}${
                    allGarments.all.length === 1 &&
                    currentGarment?.garment_id === allGarments.all[0].garment_id
                        ? ' carousel-swipe--disabled'
                        : ''
                }`}
                style={showFilters && garmentType !== type ? { opacity: 0.2 } : null}
                ref={sliderRef}
                key={type && key.toString()}
            >
                {augmentedData &&
                    augmentedData.length > 0 &&
                    augmentedData.map((item: Models.Garment, itemKey: number) => (
                        <CarouselCard
                            onClick={handleGarmentClick}
                            key={itemKey}
                            garment={item}
                            hide={shouldHide(
                                itemKey,
                                currentSlide,
                                augmentedData.length,
                                window.innerWidth / window.innerHeight < 1 ? 3 : 5
                            )}
                            cardHeight={cardHeight}
                            currentGarmentId={currentGarment?.garment_id}
                        />
                    ))}
            </Slider>
        </>
    )
}

export default CarouselGarment
