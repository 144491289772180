import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '..'
import { forceCheck } from 'react-lazyload'

export const DESKTOP_SIZE_VALUES = {
    none: 0,
    small: 0.2,
    medium: 1,
    big: 2,
}

export const MOBILE_SIZE_VALUES = {
    none: 0,
    small: 0.2,
    medium: 0.2,
    big: 15,
}

type LayoutSize = 'none' | 'small' | 'medium' | 'big'

interface LayoutSlice {
    leftPart: LayoutSize
    centerPart: LayoutSize
    rightPart: LayoutSize
    drawer: LayoutSize
    cartDrawer: LayoutSize
    modelModal: boolean
    productModal: boolean
    favoritesModal: boolean
    hideSwipe: boolean
}

const initialState: LayoutSlice = {
    leftPart: 'none',
    centerPart: 'big',
    rightPart: 'medium',
    drawer: 'none',
    cartDrawer: 'none',
    modelModal: false,
    productModal: false,
    favoritesModal: false,
    hideSwipe: false,
}

// Slice storing the layout config
export const layoutSlice = createSlice({
    initialState,
    name: 'layoutSlice',
    reducers: {
        expandLeftPart: (state: LayoutSlice) => {
            setTimeout(() => {
                forceCheck()
            }, 400)
            switch (state.leftPart) {
                case 'none':
                    state.leftPart = 'medium'
                    state.centerPart = 'medium'
                    state.drawer = 'small'
                    return
                case 'medium':
                    state.leftPart = 'big'
                    state.rightPart = 'none'
                    state.drawer = 'big'
                    return
            }
        },
        shrinkLeftPart: (state: LayoutSlice) => {
            switch (state.leftPart) {
                case 'medium':
                    state.leftPart = 'none'
                    state.centerPart = 'big'
                    state.drawer = 'none'
                    return
                case 'big':
                    state.leftPart = 'medium'
                    state.rightPart = 'medium'
                    state.centerPart = 'medium'
                    state.drawer = 'small'
                    return
            }
        },
        expandRightPart: (state: LayoutSlice) => {
            switch (state.rightPart) {
                case 'none':
                    // ---- Left part = big is the only reason the right part can be to none ----
                    state.leftPart = 'medium'
                    state.centerPart = 'medium'
                    state.rightPart = 'medium'
                    state.drawer = 'none'
                    return
            }
        },
        shrinkRightPart: (state: LayoutSlice) => {
            switch (state.rightPart) {
                case 'medium':
                    state.leftPart = state.leftPart === 'big' ? 'medium' : state.leftPart
                    state.centerPart = 'medium'
                    state.rightPart = 'none'
                    state.drawer = 'none'
                    return
            }
        },
        expandCartDrawer: (state: LayoutSlice) => {
            state.cartDrawer = 'big'
        },
        shrinkCartDrawer: (state: LayoutSlice) => {
            state.cartDrawer = 'none'
        },
        expandDrawer: (state: LayoutSlice) => {
            setTimeout(() => {
                forceCheck()
            }, 350)
            // ---- Reste hideSwipe value ----
            state.hideSwipe = false

            switch (state.drawer) {
                case 'none':
                    state.drawer = 'small'
                    state.leftPart = 'medium'
                    state.centerPart = 'medium'
                    state.drawer = 'small'
                    return
                case 'small':
                    state.drawer = 'big'
                    state.leftPart = 'big'
                    state.rightPart = 'none'
                    state.centerPart = 'medium'
                    return
            }
        },
        shrinkDrawer: (state: LayoutSlice) => {
            // ---- Reste hideSwipe value ----
            state.hideSwipe = false

            switch (state.drawer) {
                case 'small':
                    state.drawer = 'none'
                    state.leftPart = 'none'
                    state.centerPart = 'big'
                    return
                case 'big':
                    state.drawer = 'small'
                    state.leftPart = 'medium'
                    state.rightPart = 'medium'
                    state.centerPart = 'medium'
                    return
            }
        },
        closeDrawer: (state: LayoutSlice) => {
            // ---- Reste hideSwipe value ----
            state.hideSwipe = false

            state.drawer = 'none'
            state.leftPart = 'none'
            state.centerPart = 'big'
            state.rightPart = 'medium'
        },
        closeLeftPart: (state: LayoutSlice) => {
            state.drawer = 'none'
            state.leftPart = 'none'
            state.centerPart = 'big'
            state.rightPart = 'medium'
        },
        toggleModelModal: (state: LayoutSlice) => {
            state.modelModal = !state.modelModal
        },
        toggleProductModal: (state: LayoutSlice) => {
            state.productModal = !state.productModal
        },
        toggleFavoritesModal: (state: LayoutSlice) => {
            state.favoritesModal = !state.favoritesModal
        },
        hideSwipe: (state: LayoutSlice) => {
            state.hideSwipe = true
        },
    },
})

// ----- Actions -----
export const {
    expandLeftPart,
    shrinkLeftPart,
    expandRightPart,
    shrinkRightPart,
    expandCartDrawer,
    shrinkCartDrawer,
    expandDrawer,
    shrinkDrawer,
    closeDrawer,
    closeLeftPart,
    toggleModelModal,
    toggleProductModal,
    toggleFavoritesModal,
    hideSwipe,
} = layoutSlice.actions

// ---- Selector ----
export const getLeftPartValue = (state: RootState, mobile?: boolean) =>
    mobile
        ? MOBILE_SIZE_VALUES[state.layoutSlice.leftPart]
        : DESKTOP_SIZE_VALUES[state.layoutSlice.leftPart]

// ----- Center is fixed in mobile -----
export const getCenterPartValue = (state: RootState, mobile?: boolean) =>
    mobile ? 2 : DESKTOP_SIZE_VALUES[state.layoutSlice.centerPart]

export const getRightPartValue = (state: RootState, mobile?: boolean) =>
    mobile
        ? MOBILE_SIZE_VALUES[state.layoutSlice.rightPart]
        : DESKTOP_SIZE_VALUES[state.layoutSlice.rightPart]

export default layoutSlice.reducer
