import store from 'src/store'
import { apiGarment } from 'src/store/api/api-garment'
import { getQueryGender } from './query'
import { getCurrentActiveFiltersForType } from 'src/store/slices/databaseSlice'

export const isScrollableX = (el) => {
    if (el === document) {
        el = document.body
    }
    return el.scrollWidth > el.clientWidth && 'hidden' !== getComputedStyle(el).overflowX
}

export const isScrollableY = (el) => {
    if (el === document) {
        el = document.body
    }
    return el.scrollHeight > el.clientHeight && 'hidden' !== getComputedStyle(el).overflowY
}

export const isScrollable = (el) => {
    return isScrollableX(el) || isScrollableY(el)
}

export const handleCatalogScroll = (e: React.UIEvent<HTMLDivElement>, horizontal?: boolean) => {
    // ---- Var init ----
    const { scrollHeight, scrollTop, clientHeight, scrollWidth, scrollLeft, clientWidth } =
        e.currentTarget
    const allGarments = store.getState().databaseSlice.allGarments
    const garmentType = store.getState().garment.type
    const companyGenders = store.getState().profile?.company?.genders
    const getGarmentEndpoint = apiGarment.endpoints.getGarments
    const genderFilterValue = getQueryGender(companyGenders)
    const filters = getCurrentActiveFiltersForType(store.getState(), garmentType)
    const seed = store.getState().profile?.seed

    // ---- Securite ----
    if (!allGarments[garmentType]) {
        return
    }

    // ---- We build the same payload as in the custom getGarments hook ----
    const payload = {
        ...(genderFilterValue && { gender: genderFilterValue }),
        ...(allGarments[garmentType].filterGarmentId
            ? { garment_id: allGarments[garmentType].filterGarmentId }
            : null),
        ...(filters && { filter: filters }),
        ...{ seed },
        type: garmentType,
        page: allGarments[garmentType].currentPage + 1,
        init: allGarments[garmentType].init,
    }

    // ---- Get the status of the last getGarments mutation triggered ----
    const mutationKeys = Object.keys(store.getState().apiGarment.mutations)
    const { status } = getGarmentEndpoint.select(mutationKeys[mutationKeys.length - 1])(
        store.getState()
    )

    // ---- Calculate according to horizontal or vertical scroll ----
    const percentageScroll = horizontal
        ? ((scrollLeft + clientWidth) / scrollWidth) * 100
        : ((scrollTop + clientHeight) / scrollHeight) * 100

    const lastQueryResult = allGarments[garmentType]?.current

    if (percentageScroll < 70 || status === 'pending' || !lastQueryResult) {
        return
    }

    // Prevent useless call
    if (
        lastQueryResult &&
        allGarments[garmentType].all.length <=
            (allGarments[garmentType].currentPage - 1) * lastQueryResult.num_items_per_page
    ) {
        return
    }

    // Prevent loading 1 page that doesn't exist
    if (
        lastQueryResult.current_page_number * lastQueryResult.num_items_per_page >=
        lastQueryResult.total_count
    ) {
        return
    }

    store.dispatch(getGarmentEndpoint.initiate(payload, { fixedCacheKey: 'garment-mutation' }))
}
