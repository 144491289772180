import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { FetchTypeGarmentAction } from '../../store/actions/garment'

import FilterResponsive from '../filters/FilterResponsive'
import { getQueryValue } from 'src/utils/query'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'
import CartPage from 'src/pages/cart'
import useCustomHistory from 'src/utils/custom-history-hook'
import LookTracking from '../look/LookTracking'
import { sendIframeMessage } from 'src/utils/iframe'
import { SetParentHeightAction } from 'src/store/actions/profile'
import ModalAddedCart from 'src/components/ModalAddedCart'
import { handleModalAction } from 'src/store/actions/modal'
import useShowHideHeader from 'src/utils/showHideHeader'
import { useAppSelector } from 'src/store'
import {
    addFilterForType,
    getAllGarments,
    getCurrentActiveFiltersForType,
    getCurrentFiltersForType,
    getCurrentGarmentsForType,
    resetAllFilterForType,
} from 'src/store/slices/databaseSlice'
import useCustomGetGarments from 'src/utils/custom-getGarments-hook'
import { ChangeShowFiltersAction } from 'src/store/actions/filters'
import HeaderMainContent from 'src/components/header/HeaderMainContent'
import LocalFiltersContext from 'src/components/LocalFiltersContext'
import ExitButton from 'src/components/button/ExitButton'
import { ArrowLeftOutlined } from '@ant-design/icons'
import StyleBar from 'src/components/stylebar/StyleBar'
import { getPrimaryTypesForLook } from 'src/utils/typeMethods-hook'
import {
    getCenterPartValue,
    getLeftPartValue,
    getRightPartValue,
    shrinkCartDrawer,
    toggleFavoritesModal,
    toggleModelModal,
    toggleProductModal,
} from 'src/store/slices/layoutSlice'
import { Button, Drawer, Layout, Modal } from 'antd'
import ModelPage from 'src/pages/model'
import ProductsPage from 'src/pages/product'
import DrawerDesktop from '../drawer/DrawerDesktop'
import { forceCheck } from 'react-lazyload'
import FavoritesPage from 'src/pages/favorites'
import CloseButton from 'src/components/button/CloseButton'
import { HandleLookRequest } from 'src/store/actions/look'

const { Header, Content } = Layout

interface LayoutProps {
    children: React.ReactNode
}

const LayoutContainer: React.FunctionComponent<LayoutProps> = (props) => {
    const location = useLocation()
    const customHistory = useCustomHistory()
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const { showHideHeaderScrollListener } = useShowHideHeader()

    // ---- Responsive boolean to change layout ----
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth / window.innerHeight < 1)

    const company = useSelector((state: State.Root) => state.profile?.company)
    const garmentType = useSelector((state: State.Root) => state.garment?.type)
    const parentHeight = useSelector((state: State.Root) => state.profile?.parentHeight)
    const modalVisible = useSelector((state: State.Root) => state.modal.open)
    const showFilters = useAppSelector((state) => state.filters.showFilters)
    const garmentFacets = useAppSelector((state) => getCurrentFiltersForType(state, garmentType))
    const allGarments = useAppSelector((state) => getAllGarments(state))
    const currentGarmentTypePage = useAppSelector((state) =>
        getCurrentGarmentsForType(state, state.garment?.type)
    )

    const lookRequest = useAppSelector((state) => state.look.request)
    const layoutSlice = useAppSelector((state) => state.layoutSlice)
    const leftPartSize = useAppSelector((state) => getLeftPartValue(state, isMobile))
    const centerPartSize = useAppSelector((state) => getCenterPartValue(state, isMobile))
    const rightPartSize = useAppSelector((state) => getRightPartValue(state, isMobile))
    const modelModalVisible = useAppSelector((state) => state.layoutSlice.modelModal)
    const productModalVisible = useAppSelector((state) => state.layoutSlice.productModal)
    const favoritesModalVisible = useAppSelector((state) => state.layoutSlice.favoritesModal)

    const [getGarmentsTrigger] = useCustomGetGarments()

    const [centerContentMarginBottom, setCenterContentMarginBottom] = useState<number>()

    // Local filters used for multiselect
    const [localFilters, setLocalFilters] = useState<{ [key: string]: Models.Facet[] } | null>(null)
    const [lastFacetModified, setLastFacetModified] = useState<string | null>(null)
    const activeFilters = useAppSelector((state) =>
        getCurrentActiveFiltersForType(state, garmentType)
    )

    // Use to know when we highlight a subcategory or not
    const onlyOneSubCategory = useMemo(() => {
        return (
            activeFilters &&
            company.garment_category_facets &&
            activeFilters[company.garment_category_facets[garmentType]]?.length === 1 &&
            Object.keys(activeFilters).length === 1
        )
    }, [activeFilters, garmentType, company.garment_category_facets])

    useEffect(() => {
        const updateIframeHeight = (e) => {
            if (typeof e.data !== 'string') {
                return
            }
            const splitted = e.data.split(':')
            const messageName = splitted[0]
            if (messageName === 'veesual_parentsize') {
                const data = JSON.parse(splitted.slice(1).join(':'))
                if (data.height && Number(data.height) !== Number(parentHeight)) {
                    dispatch(SetParentHeightAction(data.height))
                }
            }
        }
        window.addEventListener('message', updateIframeHeight, false)
        sendIframeMessage('veesual_askparentsize', null)
        return () => {
            window.removeEventListener('message', updateIframeHeight)
        }
        // eslint-disable-next-line
    }, [parentHeight])

    useEffect(() => {
        // ---- Ajout des evenements ----
        if (document.body.addEventListener) {
            document.body.addEventListener('touchstart', showHideHeaderScrollListener, false)
            document.body.addEventListener('touchmove', showHideHeaderScrollListener, false)
            document.body.addEventListener('mousewheel', showHideHeaderScrollListener, false)
            document.body.addEventListener('DOMMouseScroll', showHideHeaderScrollListener, false)
        } else {
            document.body['attachEvent']('onmousewheel', showHideHeaderScrollListener)
        }

        return () => {
            // ---- Retrait des evenements ----
            if (document.body.removeEventListener) {
                document.body.removeEventListener('touchstart', showHideHeaderScrollListener)
                document.body.removeEventListener('touchmove', showHideHeaderScrollListener)
                document.body.removeEventListener('mousewheel', showHideHeaderScrollListener)
                document.body.removeEventListener('DOMMouseScroll', showHideHeaderScrollListener)
            } else {
                document.body['detachEvent']('onmousewheel', showHideHeaderScrollListener)
            }
        }
        // eslint-disable-next-line
    }, [])

    // We update the local filters when it's empty and we get new facets
    useEffect(() => {
        if (garmentFacets !== undefined) {
            if (!localFilters || !localFilters[garmentType]) {
                setLocalFilters({ ...localFilters, [garmentType]: garmentFacets })
            }

            // We update the count values to existing localFilters
            if (localFilters && localFilters[garmentType]) {
                // Deepcopy of the localFilters to handle the object
                const cloneLocalFilters = JSON.parse(JSON.stringify(localFilters))
                const activeFiltersKeys = Object.keys(activeFilters)

                // Loop on new facetkeys
                Object.keys(garmentFacets).forEach((facetKey) => {
                    // We don't update the data if we just modified this facet
                    if (
                        !lastFacetModified ||
                        lastFacetModified !== facetKey ||
                        activeFiltersKeys.length === 0 ||
                        (activeFilters[lastFacetModified] === undefined &&
                            lastFacetModified === facetKey)
                    ) {
                        const facetKeyData = [...cloneLocalFilters[garmentType][facetKey].data]
                        facetKeyData.forEach((data) => {
                            data.count =
                                garmentFacets[facetKey].data.find(
                                    (newData) => newData.value === data.value
                                )?.count || 0
                        })

                        cloneLocalFilters[garmentType][facetKey] = {
                            ...cloneLocalFilters[garmentType][facetKey],
                            data: facetKeyData,
                        }
                    }
                })
                setLocalFilters(cloneLocalFilters)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [garmentFacets])

    useEffect(() => {
        if (
            allGarments &&
            garmentType &&
            allGarments[garmentType]?.all.length > 0 &&
            allGarments[garmentType].all.findIndex(
                (garment) =>
                    garment.garment_id === lookRequest[garmentType.toLowerCase()]?.garment_id
            ) === -1
        ) {
            dispatch(
                HandleLookRequest({
                    lookRequest: {
                        [allGarments[garmentType].all[0].garment_type.toLowerCase()]:
                            allGarments[garmentType].all[0],
                    },
                    focus: allGarments[garmentType].all[0].garment_type,
                    keepAdditional: true,
                })
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allGarments[garmentType]])

    useEffect(() => {
        if (company && company.garment_types && company.garment_types.length && garmentType) {
            if (company.garment_types.indexOf(garmentType) === -1) {
                // ---- We check if we have a look already and set the primary type from it ----
                if (lookRequest) {
                    const lookPrimaryTypes = getPrimaryTypesForLook(lookRequest)
                    if (lookPrimaryTypes.length > 0) {
                        dispatch(FetchTypeGarmentAction(lookPrimaryTypes[0].toUpperCase()))

                        return
                    }
                }

                // ---- If not the set the default first garment_type from config ----
                dispatch(FetchTypeGarmentAction(company.garment_types[0]))

                return
            }
        }
        // eslint-disable-next-line
    }, [company])

    // ---- Window Resize useEffect ----
    useEffect(() => {
        // ---- Update the isMobile state on resize ----
        const handleResize = () => {
            setIsMobile(window.innerWidth / window.innerHeight < 1)

            // ---- Responsive animation delay ----
            setTimeout(() => {
                forceCheck()
            }, 300)
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    // ---- Use Effect to handle the margin used to shrink the look when the drawer is open on mobile ----
    useEffect(() => {
        // ---- If we open the drawer on mobile and there is no margin calculated yet ----
        if (isMobile && layoutSlice.drawer === 'small' && !centerContentMarginBottom) {
            // ---- Custom offset to overlap the drawer with the look underneath ----
            const customOffset = 4

            // ---- StyleBar Height ----
            const fixedBottomBarHeight = document.getElementById('layoutLeftContentId').clientHeight

            // ---- We need to wait until the drawer is inside the DOM ----
            const interval = setInterval(() => {
                const toggleHeaderElem = document.getElementsByClassName('drawer--toggle-container')
                const drawerContent = document.getElementsByClassName('ant-drawer-content')
                if (toggleHeaderElem.length > 0 && drawerContent.length > 0) {
                    clearInterval(interval)

                    // ---- The margin is equals to the difference between the style bar height and the drawer height with toggle header ----
                    setCenterContentMarginBottom(
                        drawerContent[0].clientHeight +
                            toggleHeaderElem[0].clientHeight -
                            (fixedBottomBarHeight + customOffset)
                    )
                }
            }, 50)

            return () => {
                clearInterval(interval)
            }
        }

        // ---- In case we have the drawer open and we go from big to small we don't need to change the value ----
        if (isMobile && layoutSlice.drawer !== 'none') {
            return
        }

        return setCenterContentMarginBottom(undefined)
    }, [layoutSlice.drawer, isMobile, centerContentMarginBottom])

    useEffect(() => {
        // ---- Detection si le current a change pour le tracking ----
        if (currentGarmentTypePage) {
            trackEvent(
                'Items Loaded',
                {
                    garment_type: garmentType,
                    current_page_number: currentGarmentTypePage.current_page_number,
                    num_items_per_page: currentGarmentTypePage.num_items_per_page,
                    total_count: currentGarmentTypePage.total_count,
                    items: currentGarmentTypePage.items,
                },
                'Core'
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGarmentTypePage])

    // ---- Handling when we show a swipe of garment type that is not on the look ----
    useEffect(() => {
        // ---- We need a look ----
        if (!lookRequest) {
            return
        }

        // ---- If the garmentType exists in look we do nothing ----
        if (lookRequest[garmentType.toLowerCase()]) {
            return
        }

        // ---- If a drawer is opened we don't handle it ----
        if (layoutSlice.drawer !== 'none') {
            return
        }

        // ---- We check the primary types from the look and if at least one exists ----
        const primaryTypesFromLook = getPrimaryTypesForLook(lookRequest)
        if (primaryTypesFromLook.length === 0) {
            return
        }

        // ---- Change garmentType as the current doesn't exist on the look and we don't want to show the carousel ----
        dispatch(FetchTypeGarmentAction(primaryTypesFromLook[0].toUpperCase()))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lookRequest, layoutSlice.drawer, isMobile, garmentType])

    const handleTitleClick = (e) => {
        e.preventDefault()
        trackEvent('Home Clicked', {}, 'Menu')
        if (location.pathname !== '/swipe') {
            customHistory.push('/swipe')
        }
    }

    const handleBackClick = () => {
        trackEvent('Return Clicked', { back_to: customHistory.getBack() }, 'Menu')
        return customHistory.goBack()
    }

    const handleOnFilterUpdate = (value?: string | null, name?: string) => {
        setLastFacetModified(name || '')
    }

    /**
     * Handle the clic on the sub header categories
     * @param facetValue string: the facetValue we just clicked
     * @returns
     */
    const handleSubHeaderClick = (facetValue: string) => {
        const isAll = facetValue === 'ALL'
        // If element is already selected we ignore
        if (
            (onlyOneSubCategory &&
                activeFilters[company.garment_category_facets[garmentType]][0] === facetValue) ||
            (Object.keys(activeFilters).length === 0 && isAll)
        ) {
            return
        }

        trackEvent(
            'Sub Header Clicked',
            { catalog_type: garmentType, catalog_category: facetValue },
            'Menu'
        )

        // We reset anyway the filters if we click on a tab
        dispatch(resetAllFilterForType(garmentType))
        setLastFacetModified(company.garment_category_facets[garmentType])

        getGarmentsTrigger({
            ...(allGarments[garmentType]?.filterGarmentId
                ? { garment_id: allGarments[garmentType].filterGarmentId }
                : null),
            type: garmentType,
            page: 1,
            filter: isAll ? {} : { [company.garment_category_facets[garmentType]]: [facetValue] },
        })

        if (!isAll) {
            dispatch(
                addFilterForType({
                    type: garmentType,
                    facetKey: company.garment_category_facets[garmentType],
                    facetValue,
                })
            )
        }
    }

    const domain = getQueryValue('domain')
    const withGarmentFilters =
        company.garment_filters === true || getQueryValue('garment_filters') !== null
    const withModelFilters =
        company.model_filters === true || getQueryValue('model_filters') !== null
    const hideRightPart = getQueryValue('hide_cart')
    const hideHeader = getQueryValue('hide_header')

    return (
        <>
            <Drawer
                destroyOnClose={true}
                closeIcon={<CloseButton />}
                className='drawer--cart'
                open={layoutSlice.cartDrawer !== 'none' && isMobile}
                onClose={() => dispatch(shrinkCartDrawer())}
                placement='bottom'
                height='90%'
            >
                <CartPage />
            </Drawer>
            <Modal
                destroyOnClose
                open={favoritesModalVisible}
                footer={null}
                closable={false}
                centered={true}
                onCancel={() => {
                    dispatch(toggleFavoritesModal())
                }}
                className='modal--favorites'
            >
                <FavoritesPage />
            </Modal>
            <Modal
                destroyOnClose
                open={modelModalVisible}
                footer={null}
                closable={false}
                centered={true}
                onCancel={() => {
                    dispatch(toggleModelModal())
                }}
                className='modal--model'
                rootClassName='modal--model--root'
            >
                <ModelPage />
            </Modal>
            <Modal
                destroyOnClose
                open={productModalVisible}
                footer={null}
                closable={false}
                centered={isMobile ? false : true}
                onCancel={() => {
                    dispatch(toggleProductModal())
                }}
                width={isMobile ? '100%' : '80%'}
                style={isMobile ? { top: 0, padding: 0 } : null}
                className='modal--product-container'
            >
                <ProductsPage />
            </Modal>
            <Modal
                destroyOnClose
                open={modalVisible}
                footer={null}
                closable={false}
                centered={true}
                width={isMobile ? '100%' : '50%'}
                onCancel={() => {
                    dispatch(handleModalAction(false))
                }}
                style={{ top: 0, padding: 0 }}
                className='modal--container'
            >
                <ModalAddedCart />
            </Modal>
            {isMobile && (
                <Modal
                    destroyOnClose
                    closable={false}
                    open={showFilters}
                    footer={null}
                    centered={false}
                    width='100vw'
                    style={{ top: 0, padding: 0 }}
                    onCancel={() => dispatch(ChangeShowFiltersAction(false))}
                    className='modal--filter-container'
                >
                    <FilterResponsive
                        allFilters={localFilters}
                        isMobile
                        onFilterUpdate={handleOnFilterUpdate}
                    />
                </Modal>
            )}

            <Layout
                className={`layout layout--container layout--page-${location.pathname.substring(
                    1
                )} ${!withGarmentFilters ? 'layout--only-topbottom' : ''}`}
            >
                {!hideHeader && (
                    <Header className='layout--header'>
                        <div className='layout--header-container'>
                            {location.pathname === '/swipe' ? (
                                <ExitButton hideMobile />
                            ) : (
                                <Button
                                    onClick={handleBackClick}
                                    icon={<ArrowLeftOutlined />}
                                    type='text'
                                    className='button layout--header-burger-button button--desktop'
                                />
                            )}
                            {/* Main Title */}
                            <div className={`layout--header-title`}>
                                {domain == 'christmas.com' ? (
                                    <h1
                                        className={`title title--h1 title--h1-christmas title--main ${
                                            company.garment_types.length > 2 && withGarmentFilters
                                                ? 'title--hide-overflow'
                                                : ''
                                        }`}
                                        onClick={handleTitleClick}
                                    >
                                        {'Christmas outfit creator'}
                                    </h1>
                                ) : (
                                    <h1
                                        className={`title title--h1 title--main ${
                                            company.garment_types.length > 2 && withGarmentFilters
                                                ? 'title--hide-overflow'
                                                : ''
                                        }`}
                                        onClick={handleTitleClick}
                                    >
                                        {t('layout.title')}
                                    </h1>
                                )}
                            </div>
                        </div>

                        {/* Main Content */}
                        <HeaderMainContent onBackClick={handleBackClick} />
                    </Header>
                )}

                <div className={`layout--content`}>
                    <LocalFiltersContext.Provider
                        value={{
                            localFilters,
                            onSubHeaderClick: handleSubHeaderClick,
                            onlyOneSubCategory,
                            onFilterClick: (show) => {
                                trackEvent(
                                    'Filter Clicked',
                                    { catalog_type: garmentType, filter_type: 'item' },
                                    'Menu'
                                )
                                dispatch(ChangeShowFiltersAction(show))
                            },
                        }}
                    >
                        <Content id='layoutLeftContentId' className='layout--left-part'>
                            <StyleBar />
                        </Content>
                        {!isMobile && (
                            <div
                                className='layout--drawer-desktop'
                                style={{
                                    flex: leftPartSize,
                                    ...(hideRightPart && layoutSlice.leftPart === 'medium'
                                        ? { maxWidth: '33%' }
                                        : null),
                                }}
                            >
                                <DrawerDesktop />
                            </div>
                        )}
                    </LocalFiltersContext.Provider>
                    <div className='layout--main-overlay' style={{ flex: centerPartSize }}>
                        <Content
                            id='layoutScrollableContent'
                            className={`layout--center-content`}
                            style={
                                isMobile && layoutSlice.drawer !== 'none'
                                    ? { marginBottom: centerContentMarginBottom }
                                    : null
                            }
                        >
                            <LocalFiltersContext.Provider
                                value={{
                                    localFilters,
                                    onSubHeaderClick: handleSubHeaderClick,
                                    onlyOneSubCategory,
                                    onFilterClick: (show) => {
                                        trackEvent(
                                            'Filter Clicked',
                                            { catalog_type: garmentType, filter_type: 'item' },
                                            'Menu'
                                        )
                                        dispatch(ChangeShowFiltersAction(show))
                                    },
                                }}
                            >
                                {props?.children}
                            </LocalFiltersContext.Provider>
                        </Content>
                    </div>
                    {(!hideRightPart || showFilters) && (
                        <Content
                            id='layoutRightContentId'
                            className={`layout--right-content`}
                            style={{
                                flex: rightPartSize,
                            }}
                        >
                            {showFilters && window.innerWidth / window.innerHeight >= 1 ? (
                                <FilterResponsive
                                    allFilters={localFilters}
                                    onFilterUpdate={handleOnFilterUpdate}
                                />
                            ) : (
                                <>
                                    {((!showFilters && location.pathname !== '/model') ||
                                        !withModelFilters) && <CartPage />}
                                </>
                            )}
                        </Content>
                    )}

                    <LookTracking />
                </div>
            </Layout>
        </>
    )
}

export default LayoutContainer
