import React, { useMemo, useRef, useState } from 'react'

import { trackEvent } from 'src/utils/tracking'
import { useSelector } from 'react-redux'
import ImageSmooth from './ImageSmooth'
import { resizeImage } from 'src/utils/image'
import Slider from 'react-slick'
import Lightbox, { Plugin } from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import { Button, Col, Row } from 'antd'
import FavoriteButton from '../button/FavoriteButton'
import { getZoomIcon } from 'src/utils/icon'

interface ImagePreviewProductProps {
    garment: Models.GarmentDetail
}

let checkForDragCardCarousel

const ImagePreviewProduct: React.FunctionComponent<ImagePreviewProductProps> = (props) => {
    const { garment } = props

    const sliderRef = useRef(null)

    const company = useSelector((state: State.Root) => state.profile?.company)
    const [visible, setVisible] = useState(false)
    const [current, setCurrent] = useState(0)
    const swipeTrackingEvent: any = useRef()

    const garmentImgRatio = 100 / (company?.garment_image_ratio || 0.66)
    const imageUrls = useMemo(
        () =>
            garment.image_urls && garment.image_urls.length
                ? garment.image_urls
                : [garment.image_url],
        [garment]
    )

    // ---- array of slides containing image data used by the lightbox ----
    const imagesSlides = useMemo(() => {
        return Array.from(imageUrls, (imageUrl) => {
            return { src: imageUrl }
        })
    }, [imageUrls])

    const handleZoomClick = (e) => {
        e.stopPropagation()
        trackEvent('Zoom Opened', [garment, { item_index_selected: current }], 'Item Detail')
        setVisible(true)
    }

    const handleDotClick = (e, key) => {
        e.stopPropagation()
        trackEvent(
            'Carousel Selected',
            [garment, { item_index_selected: current, carousel_type: 'dot' }],
            'Item Detail'
        )
        setCurrent(key)
        sliderRef.current?.slickGoTo(key, true)
    }

    const handleThumbnailClick = (e, key) => {
        e.stopPropagation()
        trackEvent(
            'Carousel Selected',
            [garment, { item_index_selected: current, carousel_type: 'thumbnail' }],
            'Item Detail'
        )
        setCurrent(key)
        sliderRef.current?.slickGoTo(key, true)
    }

    const handleCardClick = (e: React.MouseEvent<HTMLElement>) => {
        const mouseUp = e.clientX

        // it's swiping so we ignore
        if (mouseUp > checkForDragCardCarousel + 6 || mouseUp < checkForDragCardCarousel - 6) {
            return
        }

        handleZoomClick(e)
    }

    const mouseDownCoords = (e: React.MouseEvent<HTMLElement>) => {
        checkForDragCardCarousel = e.clientX
    }

    const settings = {
        focusOnSelect: false,
        slidesToShow: 1,
        onSwipe: () => {
            swipeTrackingEvent.current = {
                name: 'Carousel Selected',
                properties: { carousel_type: 'image' },
                category: 'Item Detail',
            }
        },
        beforeChange: (unused, next) => {
            if (swipeTrackingEvent.current) {
                // ---- Oblige de faire comme ca car beforeChange se lance apres le onSwipe ----
                trackEvent(
                    swipeTrackingEvent.current.name,
                    [garment, { item_index_selected: next }, swipeTrackingEvent.current.properties],
                    swipeTrackingEvent.current.category
                )
                swipeTrackingEvent.current = null
            }
            setCurrent(next)
        },
    }

    return (
        <>
            <Col className='image image--preview-container'>
                <div className='image--preview-relative override_img_container'>
                    <Slider
                        {...settings}
                        ref={sliderRef}
                        className='image--preview-carousel'
                        key={garment.garment_id}
                    >
                        {imageUrls.length > 0 &&
                            imageUrls.map((imageItem: string) => (
                                <div
                                    className='image--preview-carousel-image'
                                    onMouseDown={mouseDownCoords}
                                    onMouseUp={(e) => handleCardClick(e)}
                                    key={imageItem}
                                >
                                    <ImageSmooth
                                        src={resizeImage(imageItem, { width: 800 })}
                                        ratio={garmentImgRatio}
                                        lazyload={false}
                                    />
                                </div>
                            ))}
                    </Slider>
                    <div className='image--preview-bottom'>
                        <span className='empty'></span>
                        <Button
                            icon={getZoomIcon({ fontSize: 24 })}
                            type='text'
                            className='button'
                            onClick={handleZoomClick}
                        ></Button>
                    </div>
                    <FavoriteButton
                        data={garment}
                        className='image--preview-top'
                        eventCategory='Item Detail'
                    />
                </div>
                <Lightbox
                    open={visible}
                    close={() => setVisible(false)}
                    slides={imagesSlides}
                    styles={{ container: { backgroundColor: '#000A' } }}
                    plugins={[Zoom as Plugin]}
                />
            </Col>

            {imageUrls.length > 1 && (
                <>
                    <Row className='image--dot-container'>
                        {imageUrls.map((item: string, key: number) => (
                            <div
                                key={item}
                                className={`image--dot ${
                                    key === current ? 'image--dot-active' : ''
                                }`}
                                onClick={(e) => handleDotClick(e, key)}
                            />
                        ))}
                    </Row>
                    <Row className='image--row'>
                        {imageUrls.map((item: string, key: number) => (
                            <div
                                key={item}
                                className='image--minimize override_img_container'
                                onClick={(e) => handleThumbnailClick(e, key)}
                            >
                                <ImageSmooth
                                    src={resizeImage(item, { width: 800 })}
                                    ratio={garmentImgRatio}
                                    lazyload={false}
                                />
                            </div>
                        ))}
                    </Row>
                </>
            )}
        </>
    )
}

export default ImagePreviewProduct
