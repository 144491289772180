import { useDispatch } from 'react-redux'
import { useAppSelector } from 'src/store'
import { SetCartAddedAction } from 'src/store/actions/garment'
import { handleModalAction } from 'src/store/actions/modal'
import { trackEvent } from './tracking'
import { toggleProductModal } from 'src/store/slices/layoutSlice'
import { useEffect, useState } from 'react'
import { useGetLookRecommendationsQuery } from 'src/store/api/api-look'
import { FetchRecommendationsAction } from 'src/store/actions/look'

export default function useShowRecommendationsModal() {
    const dispatch = useDispatch()
    const company = useAppSelector((state) => state.profile?.company)
    const modalOpen = useAppSelector((state) => state.modal.open)
    const look = useAppSelector((state) => state.look.request)
    const productModal = useAppSelector((state) => state.layoutSlice.productModal)

    const [payload, setPayload] = useState<API.Req.GetLook>()

    const { data, isFetching } = useGetLookRecommendationsQuery(payload, {
        skip: !payload,
        refetchOnMountOrArgChange: true,
    })

    function showModal(
        garment_id: string | string[],
        eventProperties: any = {},
        eventCategory = 'Layout'
    ) {
        if (company.enable_recommendations && !modalOpen) {
            trackEvent('Popin Cart Opened', eventProperties, eventCategory)
            dispatch(SetCartAddedAction([].concat(garment_id)))
            setPayload({
                identity_id: look.model.identity_id,
                garment_id,
                look_id: look.look_id,
            })
        }
    }

    useEffect(() => {
        // ---- Close detail product modal if open AFTER getting a new lookRecommendations ----
        if (data && productModal) {
            dispatch(toggleProductModal())
        }

        // ---- if we have some new data, we change modal value in store and add the recommendations aswell ----
        if (data) {
            dispatch(FetchRecommendationsAction(data))
            dispatch(handleModalAction(true))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        // ---- If we had a payload and the modal is now closed we reset the payload -----
        if (!modalOpen && payload) {
            setPayload(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen])

    return { showModal, isLoading: isFetching }
}
