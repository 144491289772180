import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleModalAction } from 'src/store/actions/modal'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'
import Slider from 'react-slick'
import ImageSmooth from './image/ImageSmooth'
import { resizeImage } from 'src/utils/image'
import CardFavorites from './card/cardFavorites'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { GetLookCall } from 'src/store/actions/look'
import useCustomHistory from 'src/utils/custom-history-hook'
import { Button } from 'antd'
import { sendWKGotoCart } from 'src/utils/wkwebview'
import CloseButton from './button/CloseButton'
import { usePostPricesMutation } from 'src/store/api/api-price'

let checkForDragCardCarousel

export default function ModalAddedCart() {
    const { t } = useCustomTranslation()
    const dispatch = useDispatch()
    const customHistory = useCustomHistory()

    const cartUrl = useSelector((state: State.Root) => state.profile?.company?.external_cart_url)
    const look = useSelector((state: State.Root) => state.look?.request)
    const lookRecommendations = useSelector((state: State.Root) => state.look?.recommendations)
    const company = useSelector((state: State.Root) => state.profile?.company)
    const cartAdded = useSelector((state: State.Root) => state.garment?.cartAdded)

    const [postPrices] = usePostPricesMutation()

    const [arrowVerticalPos, setArrowVerticalPos] = useState<number>(null)
    const [convertedPrices, setConvertedPrices] = useState<Models.Price[]>()

    const swipeTrackingEvent: any = useRef()

    const noLookRecommendations = useMemo(
        () => !lookRecommendations || lookRecommendations.length === 0,
        [lookRecommendations]
    )

    useEffect(() => {
        let interval = null
        function handleResize() {
            const content = document.getElementById('modalCarouselContainer')
            if (content) {
                clearInterval(interval)
                // We don't need to add the 8px padding from the bottom because it starts above it
                setArrowVerticalPos(
                    (window.innerWidth / window.innerHeight < 1 ? 0 : 24) + content.clientHeight / 2
                )
            }
        }
        interval = setInterval(() => {
            handleResize()
        }, 100)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, [])

    // ---- Get Converted Prices Use Effect ----
    useEffect(() => {
        if (!lookRecommendations || !company.enable_prices_api) {
            return
        }

        postPrices({
            garment_ids: lookRecommendations.map(
                (localLook) => localLook[localLook.recommendation_type].garment_id
            ),
        })
            .unwrap()
            .then((resp) => {
                setConvertedPrices(resp)
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lookRecommendations])

    const closeModal = () => {
        dispatch(handleModalAction(false))
    }

    const handleContinueShop = (e) => {
        e.stopPropagation()
        trackEvent('Continue shopping Clicked', [look], 'Popin Cart')
        closeModal()
    }

    const handleGoToCart = (e) => {
        e.stopPropagation()
        if (sendWKGotoCart()) {
            e.preventDefault()
        }
        trackEvent('Go to cart Clicked', [look], 'Popin Cart')
    }

    const mouseDownCoords = (e: React.MouseEvent<HTMLElement>) => {
        checkForDragCardCarousel = e.clientX
    }

    const handleLookClick = (e: React.MouseEvent<HTMLElement>, newLook: Models.Look) => {
        const mouseUp = e.clientX

        // it's swiping so we ignore
        if (mouseUp > checkForDragCardCarousel + 6 || mouseUp < checkForDragCardCarousel - 6) {
            return
        }
        trackEvent('Outfit Detailed', newLook, 'Popin Cart')
        dispatch(GetLookCall(newLook.look_id))
        customHistory.push('/swipe')
        closeModal()
    }

    const CarouselNextArrow = (arrowProps) => {
        const { className, onClick } = arrowProps

        if (!onClick) {
            return null
        }

        const handleClick = (e) => {
            swipeTrackingEvent.current = {
                name: `Swipe right`,
                properties: { swipe_from: 'arrow' },
                category: 'Popin Cart',
            }
            onClick(e)
        }

        return (
            <div
                className={`${className} carousel-swipe--arrow carousel-swipe--right`}
                style={{ bottom: arrowVerticalPos }}
                onClick={handleClick}
            >
                <RightOutlined />
            </div>
        )
    }

    const CarouselPrevArrow = (arrowProps) => {
        const { className, onClick } = arrowProps

        if (!onClick) {
            return null
        }

        const handleClick = (e) => {
            swipeTrackingEvent.current = {
                name: `Swipe left`,
                properties: { swipe_from: 'arrow' },
                category: 'Popin Cart',
            }
            onClick(e)
        }

        return (
            <div
                className={`${className} carousel-swipe--arrow carousel-swipe--left`}
                style={{ bottom: arrowVerticalPos }}
                onClick={handleClick}
            >
                <LeftOutlined />
            </div>
        )
    }

    const settings = {
        infinite: false,
        lazyLoad: true,
        swipeToSlide: true,
        slidesToShow: window.innerWidth / window.innerHeight < 1 ? 1 : 3,
        nextArrow: <CarouselNextArrow />,
        prevArrow: <CarouselPrevArrow />,
        onSwipe: (direction) => {
            const adaptedDirection = direction == 'right' ? 'left' : 'right'
            swipeTrackingEvent.current = {
                name: `Swipe ${adaptedDirection}`,
                properties: { swipe_from: 'image' },
                category: 'Popin Cart',
            }
        },
        beforeChange: (current, next) => {
            if (swipeTrackingEvent.current) {
                // ---- Oblige de faire comme ca car beforeChange se lance apres le onSwipe ----
                trackEvent(
                    swipeTrackingEvent.current.name,
                    [{ swipe_index: next }, swipeTrackingEvent.current.properties],
                    swipeTrackingEvent.current.category
                )
                swipeTrackingEvent.current = null
            }
        },
        arrows: true,
        touchThreshold: 15,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: `30px`,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    }

    const lookImgRatio = 100 / (company?.look_image_ratio || 0.66)
    const garmentImgRatio = 100 / (company?.garment_image_ratio || 0.66)

    const multipleGarmentAdded = cartAdded.length > 1

    return (
        <div
            className={`modal--container ${
                noLookRecommendations ? 'modal--no-recommendation' : ''
            }`}
        >
            <div className='modal--top-container'>
                <CloseButton
                    className='modal--close'
                    onClick={() => dispatch(handleModalAction())}
                    style={{ top: 9 }}
                />
                <div className='modal--title'>
                    {t(`cart.modal.title${multipleGarmentAdded ? '_more' : '_one'}`)}
                </div>
                <div
                    className='modal--button-container'
                    style={noLookRecommendations ? { marginBottom: 16 } : undefined}
                >
                    <Button
                        className='button--modal'
                        onClick={handleContinueShop}
                        type='default'
                        size='large'
                    >
                        {t('cart.modal.continue_shop')}
                    </Button>
                    <Button
                        className='button--primary button--modal'
                        onClick={handleGoToCart}
                        href={cartUrl}
                        target={company.link_target_parent ? '_parent' : '_blank'}
                        type='primary'
                        size='large'
                    >
                        {t('cart.go_to_cart')}
                    </Button>
                </div>
                {!noLookRecommendations && (
                    <div className='modal--title'>{t('cart.modal.client_matched_with')}</div>
                )}
            </div>

            {!noLookRecommendations && (
                <div id='modalCarouselContainer' className='modal--carousel-container'>
                    <Slider {...settings}>
                        {lookRecommendations?.map((localLook) => (
                            // FIX: Need React Fragment so React Slick doesn't add style on the div
                            <React.Fragment key={localLook.look_id}>
                                <div className='card-model--image-container modal--image-container'>
                                    <div
                                        className='card-model--image'
                                        onMouseDown={mouseDownCoords}
                                        onMouseUp={(e) => handleLookClick(e, localLook)}
                                    >
                                        <ImageSmooth
                                            className='card-model--background'
                                            src={
                                                localLook.image_urls
                                                    ? resizeImage(localLook.image_urls[0], {
                                                          width: 800,
                                                      })
                                                    : 'none'
                                            }
                                            ratio={lookImgRatio}
                                            loader={true}
                                            cover
                                        />
                                    </div>
                                    <CardFavorites
                                        data={localLook[localLook.recommendation_type]}
                                        ratio={garmentImgRatio}
                                        isOutfit={false}
                                        noClick
                                        customImageWidth='50%'
                                        eventCategory='Popin Cart'
                                        convertedPrice={convertedPrices?.find(
                                            (price) =>
                                                price.garment_id ===
                                                localLook[localLook.recommendation_type].garment_id
                                        )}
                                    />
                                </div>
                            </React.Fragment>
                        ))}
                    </Slider>
                </div>
            )}
        </div>
    )
}
