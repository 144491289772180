import { inIframe, sendIframeMessage, sendIframeMessageWithResponse } from 'src/utils/iframe'
import { getQueryValue } from 'src/utils/query'
import store from 'src/store'
import { HandleLookRequest } from 'src/store/actions/look'
import { shrinkDrawer } from 'src/store/slices/layoutSlice'
import { formattedPrice } from './price'
import { inWKWebView, sendWKScriptMessage } from './wkwebview'

export const isGarmentSizePreorder = (value: string, optionSizes: Models.OldGarmentSize[]) => {
    if (value) {
        for (const optionSize of optionSizes) {
            if (optionSize.value == value) {
                return optionSize.preorder === true
            }
        }
    }

    let hasStock = false
    let hasPreorder = false
    for (const optionSize of optionSizes) {
        if (optionSize.in_stock !== false) {
            hasStock = true
        }
        if (optionSize.preorder === true) {
            hasPreorder = true
        }
    }

    return hasPreorder && !hasStock
}

export const getGarmentSizeLabel = (
    value: string,
    optionSizes: { label: string; value: string }[]
) => {
    for (const optionSize of optionSizes) {
        if (optionSize.value == value) {
            return optionSize.label
        }
    }
    return null
}

export const getCurrentModelGarmentSizes = (modelGarmentSizesField?: string) => {
    let modelGarmentSizes = null
    const model = store.getState().look.current?.model
    if (model) {
        if (model[modelGarmentSizesField]) {
            modelGarmentSizes = model[modelGarmentSizesField]
        } else if (model['model_garment_sizes']) {
            modelGarmentSizes = model['model_garment_sizes']
        } else if (model['model_garment_size']) {
            modelGarmentSizes = [model['model_garment_size']]
        }
    }

    return modelGarmentSizes
}

export const getOldGarmentSizeLabelValue = (
    size: Models.OldGarmentSize,
    modelGarmentSizes: string[] = null,
    modelName: string = null,
    productCurrency?: string
) => {
    const label = typeof size.label === 'string' ? size.label.toUpperCase() : size.label
    const labelValue = { label: label, value: size.value }
    if (typeof size.in_stock !== 'undefined') {
        labelValue['in_stock'] = size.in_stock
    }
    if (typeof size.preorder !== 'undefined') {
        labelValue['preorder'] = size.preorder
    }

    // ---- Use extra to show size price instead of model extra ----
    if (size.price) {
        const priceFloat = store.getState().profile?.company?.price_float
        labelValue['extra'] = `${formattedPrice(
            size.price,
            productCurrency || 'EUR',
            priceFloat || 2
        )}`

        return labelValue
    }

    if (modelGarmentSizes && modelGarmentSizes.indexOf(label) !== -1) {
        labelValue['extra'] = ['product.worn_by', { modelName }]
    }
    return labelValue
}

export const getGarmentSizeLabelValue = (
    value: Models.GarmentSizeFieldValue,
    modelGarmentSizes: string[] = null,
    modelName: string = null
) => {
    const labelValue = {
        label: value.label || value.value,
        value: value.value,
        imageUrl: value.image_url,
        svgUrl: value.svg_url,
        filters: value.filters,
    }

    if (modelGarmentSizes && modelGarmentSizes.indexOf(value.value) !== -1) {
        labelValue['extra'] = ['product.worn_by', { modelName }]
    }

    return labelValue
}

export const getGarmentOptionSizes = (garment: Models.Garment) => {
    const model = store.getState().look.current?.model

    return garment.product_sizes.map((size) =>
        getOldGarmentSizeLabelValue(
            size,
            getCurrentModelGarmentSizes(
                `model_${garment.garment_type.toLowerCase()}_garment_sizes`
            ),
            model?.model_name,
            garment.product_currency
        )
    )
}

export const addGarmentSizesToCart = (params: any[], callback: (success: boolean) => void) => {
    // ---- Debug ----
    for (const one of params) {
        console.log(one.garment.garment_id, one.currentSize, one.from)
    }

    // ---- Mode demo ----
    const origin = getQueryValue('origin')
    if (
        !inWKWebView() &&
        (!origin || origin.match(/^https?:\/\/(generateapp\.localhost|app\.veesual\.ai)\//))
    ) {
        setTimeout(() => callback(true), 2000)
        return
    }

    // ---- En fonction du client ----
    if (inIframe() && getQueryValue('domain') == 'marinehenrion.com') {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Recuperation du variation_id ----
            let variation_id = null
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.variation_id !== 'undefined') {
                    variation_id = size.variation_id
                }
            }

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: 'https://marinehenrion.com',
                    data: {
                        quantity: 1,
                        'add-to-cart': garment.product_external_id,
                        product_id: garment.product_external_id,
                        variation_id: variation_id,
                    },
                },
            })
        }

        // ---- Refresh du cart ----
        payloads.push({
            type: 'javascript',
            payload: `jQuery(document.body).trigger('wc_fragment_refresh');`,
        })

        // ---- Lancement du message avec callback ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            payloads,
            (responses) => {
                if (responses === false) {
                    return callback(false)
                }
                return callback(true)
            },
            params.length * 10000
        )
    }
    // ---- Operation de noel ----
    else if (getQueryValue('domain') == 'christmas.com') {
        // ---- Ouverture de lien externe si possible ----
        if (params.length != 1) {
            alert(
                `This is a demo experience. Get in touch with Veesual to implement the cart! We wish you a merry Christmas.`
            )
        } else {
            alert(
                `This is a demo experience. Get in touch with Veesual to implement the cart! We wish you a merry Christmas.`
            )
            window.open(params[0].garment.product_external_url, '_blank')
        }

        // ---- Callback ----
        callback(true)
    }
    // ---- La redoute ----
    else if (
        inIframe() &&
        [
            'laredoute.fr',
            'laredoute.es',
            'laredoute.co.uk',
            'laredoute.pt',
            'laredoute.be',
            'laredoute.ch',
        ].indexOf(getQueryValue('domain')) !== -1
    ) {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Recuperation du variation_id ----
            let offer_id = null,
                basket_size = null,
                pres_code = null,
                category_id = null
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.offer_id !== 'undefined') {
                    offer_id = size.offer_id || '-'
                    basket_size = size.basket_size || ''
                    pres_code = size.pres_code || ''
                    category_id = size.category_id || ''
                }
            }

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: '/shoppingbasket/additemtocart',
                    data: {
                        quant: '1',
                        basketSize: basket_size,
                        presCode: pres_code,
                        itemOfferId: basket_size ? '-' : offer_id,
                        contextCategoryId: category_id,
                        productId: garment.product_external_id,
                        loyalty: false,
                        loyaltySource: 'pdp',
                        origin: 'treestructureflyout',
                    },
                },
            })
        }

        // ---- Ajout au payloads ----
        payloads.push({
            type: 'request',
            payload: {
                method: 'GET',
                url: '/shoppingbasket/basketitemcount/?_=' + Date.now(),
            },
        })

        // ---- Lancement du message avec callback ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            payloads,
            (responses) => {
                if (responses === false) {
                    return callback(false)
                }
                const itemCount = parseInt(responses.pop())
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `jQuery('#basketIcon .badge').text(${itemCount}).css('display', 'flex');`,
                    },
                ])
                return callback(true)
            },
            params.length * 5000
        )
    }
    // ---- Sustainabl ----
    else if (inIframe() && getQueryValue('domain') == 'sustainabl.com') {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Recuperation du variation_id ----
            let variant_id = null,
                variant_color = null,
                variant_size = null
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.variant_id !== 'undefined') {
                    variant_id = size.variant_id
                    variant_color = size.variant_color
                    variant_size = size.variant_size
                }
            }

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: '/cart/add',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/javascript',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    dataType: 'json',
                    data: {
                        'options[Size]': variant_size,
                        Color: variant_color,
                        quantity: '1',
                        form_type: 'product',
                        utf8: '✓',
                        id: String(variant_id),
                        sections: ['cart-icon-bubble'],
                        sections_url: '/pages/mix-and-match',
                    },
                },
            })
        }

        // ---- Lancement du message avec callback ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            payloads,
            (responses) => {
                if (responses === false) {
                    return callback(false)
                }
                const response = responses.pop()
                let escapedHtml = JSON.parse(response)
                if (!escapedHtml.sections || !escapedHtml.sections['cart-icon-bubble']) {
                    return callback(false)
                }
                escapedHtml = JSON.stringify(String(escapedHtml.sections['cart-icon-bubble']))
                escapedHtml = escapedHtml.substring(1, escapedHtml.length - 1)
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `
                            (function(){
                                const tmpSectionParser = document.createElement('body');
                                tmpSectionParser.innerHTML = "${escapedHtml}";
                                document.getElementById('cart-icon-bubble').innerHTML = tmpSectionParser.querySelector('#shopify-section-cart-icon-bubble').innerHTML;
                            })();
                        `,
                    },
                ])
                return callback(true)
            },
            params.length * 5000
        )
    }
    // ---- Jules ----
    else if (inIframe() && getQueryValue('domain') == 'jules.com') {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Recuperation du variation_id ----
            let offer_id = null
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.offer_id !== 'undefined') {
                    offer_id = size.offer_id
                }
            }

            // ---- Url en fonction de l'origin ----
            let postUrl = '/on/demandware.store/Sites-Jules-SFRA-Site/fr_FR/Cart-AddProduct'
            if (origin) {
                if (origin.match(/\/nl-be\//)) {
                    postUrl = '/on/demandware.store/Sites-Jules-BE-SFRA-Site/nl_BE/Cart-AddProduct'
                } else if (origin.match(/\/fr-be\//)) {
                    postUrl = '/on/demandware.store/Sites-Jules-BE-SFRA-Site/fr_BE/Cart-AddProduct'
                }
            }

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: postUrl,
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    data: {
                        pid: offer_id,
                        quantity: '1',
                        options: '[]',
                    },
                },
            })
        }

        // ---- Lancement du message avec callback ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            payloads,
            (responses) => {
                if (responses === false) {
                    return callback(false)
                }
                const response = responses.pop()
                const parsedResponse = JSON.parse(response)
                if (
                    !parsedResponse ||
                    parsedResponse.error ||
                    !parsedResponse.cart ||
                    !parsedResponse.cart.numItems
                ) {
                    return callback(false)
                }
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `jQuery('.minicart-quantity').text('${parsedResponse.cart.numItems}');`,
                    },
                ])
                return callback(true)
            },
            params.length * 10000
        )
    }
    // ---- Gemo ----
    else if (inIframe() && getQueryValue('domain') == 'gemo.fr') {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Recuperation du variation_id ----
            let offer_id = null
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.offer_id !== 'undefined') {
                    offer_id = size.offer_id
                }
            }

            // ---- Url de post ----
            const postUrl = '/ajax.V1.php/fr_FR/Rbs/Commerce/Cart/Lines'

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: postUrl,
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json, text/plain, */*',
                        'X-HTTP-Method-Override': 'POST',
                    },
                    dataType: 'json',
                    data: {
                        websiteId: 100199,
                        sectionId: 112008,
                        pageId: 100718,
                        data: {
                            lines: [
                                {
                                    productId: parseInt(offer_id),
                                    category: 'shippingCategories',
                                    successModal: {
                                        pageFunction: 'Rbs_Catalog_ProductAddedToCart',
                                        themeName: 'Project_Gemotheme',
                                    },
                                    storeId: 0,
                                },
                            ],
                            clientDate: new Date().toString(),
                        },
                        detailed: false,
                        URLFormats: 'canonical',
                        visualFormats: 'shortCartItem',
                        referer: getQueryValue('origin', ''),
                    },
                },
            })
        }

        // ---- Lancement du message avec callback ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            payloads,
            (responses) => {
                if (responses === false) {
                    return callback(false)
                }
                const response = responses.pop()
                const parsedResponse = JSON.parse(response)
                if (
                    !parsedResponse ||
                    !parsedResponse.dataSets ||
                    !parsedResponse.dataSets.linesAdded ||
                    !parsedResponse.dataSets.linesAdded.length ||
                    !parsedResponse.dataSets.common ||
                    !parsedResponse.dataSets.common.lineCount
                ) {
                    return callback(false)
                }
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `jQuery('.badge').text('${parsedResponse.dataSets.common.lineCount}');`,
                    },
                ])
                return callback(true)
            },
            params.length * 5000
        )
    }
    // ---- Sergent Major ----
    else if (
        inIframe() &&
        [
            'sergent-major.com',
            'sergent-major.be',
            'sergent-major.de',
            'dpam.com',
            'dpam.be',
            'dpam.it',
        ].indexOf(getQueryValue('domain')) !== -1
    ) {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Recuperation du variation_id ----
            let offer_id = null
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.offer_id !== 'undefined') {
                    offer_id = size.offer_id
                }
            }

            // ---- Url en fonction de l'origin ----
            let postUrl = null
            if (getQueryValue('domain', '') == 'sergent-major.com') {
                postUrl = '/on/demandware.store/Sites-SergentMajor-Site/fr_FR/Cart-AddProduct'
            } else if (getQueryValue('domain', '') == 'sergent-major.be') {
                postUrl = '/on/demandware.store/Sites-SergentMajor-Site/fr_BE/Cart-AddProduct'
            } else if (getQueryValue('domain', '') == 'sergent-major.de') {
                postUrl = '/on/demandware.store/Sites-SergentMajor-Site/de_DE/Cart-AddProduct'
            } else if (getQueryValue('domain', '') == 'dpam.com') {
                postUrl = '/on/demandware.store/Sites-DPAM-Site/fr_FR/Cart-AddProduct'
            } else if (getQueryValue('domain', '') == 'dpam.be') {
                postUrl = '/on/demandware.store/Sites-DPAM-Site/fr_BE/Cart-AddProduct'
            } else if (getQueryValue('domain', '') == 'dpam.it') {
                postUrl = '/on/demandware.store/Sites-DPAM-Site/it_IT/Cart-AddProduct'
            } else {
                return callback(false)
            }

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: postUrl,
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    data: {
                        pid: offer_id,
                        quantity: '1',
                        options: '[]',
                    },
                },
            })
        }

        // ---- Lancement du message avec callback ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            payloads,
            (responses) => {
                if (responses === false) {
                    return callback(false)
                }
                const response = responses.pop()
                const parsedResponse = JSON.parse(response)
                if (
                    !parsedResponse ||
                    parsedResponse.error ||
                    !parsedResponse.cart ||
                    !parsedResponse.cart.numItems
                ) {
                    return callback(false)
                }
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `jQuery('.minicart-quantity').text('${parsedResponse.cart.numItems}').removeClass('d-none');`,
                    },
                ])
                return callback(true)
            },
            params.length * 1000
        )
    }
    // ---- Eileen Fisher ----
    else if (inIframe() && ['eileenfisher.com'].indexOf(getQueryValue('domain')) !== -1) {
        // ---- Recuperation du base url ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            [
                {
                    type: 'javascript',
                    payload: `(function(){ const match = document.body.innerHTML.match(/\\/on\\/demandware\\.store\\/Sites-ef-Site\\/[^/]+\\//); return match ? match[0] : null })()`,
                },
            ],
            (executeResponses) => {
                // ---- Gestion d'erreur ----
                if (
                    executeResponses === false ||
                    executeResponses.length !== 1 ||
                    !executeResponses[0]
                ) {
                    return callback(false)
                }

                // ---- Initialisation ----
                const payloads = []

                // ---- Pour chaque param ----
                for (const one of params) {
                    // ---- Raccourci ----
                    const { garment, currentSize } = one

                    // ---- Recuperation du variation_id ----
                    let offer_id = null
                    for (const size of garment.product_sizes) {
                        if (size.value == currentSize && typeof size.offer_id !== 'undefined') {
                            offer_id = size.offer_id
                        }
                    }

                    // ---- Url en fonction de l'origin ----
                    const postUrl = executeResponses[0] + 'Cart-AddProduct'

                    // ---- Ajout au payload ----
                    payloads.push({
                        type: 'jquery_ajax',
                        payload: {
                            url: postUrl,
                            method: 'POST',
                            dataType: 'json',
                            data: {
                                pid: offer_id,
                                quantity: '1',
                                addedLocation: 'pdp',
                                options: '[]',
                            },
                        },
                    })
                }

                // ---- Using jquery to handle the basic auth in dev ----
                sendIframeMessageWithResponse(
                    'veesual_execute',
                    payloads,
                    function (responses) {
                        if (responses === false) {
                            return callback(false)
                        }
                        const parsedResponse = responses.pop()
                        if (
                            !parsedResponse ||
                            parsedResponse.error ||
                            !parsedResponse.cart ||
                            !parsedResponse.cart.numItems
                        ) {
                            return callback(false)
                        }
                        let escapedHtml = JSON.stringify(
                            String(parsedResponse.currentItemRenderedTemplate)
                        )
                        escapedHtml = escapedHtml.substring(1, escapedHtml.length - 1)
                        sendIframeMessage('veesual_execute', [
                            {
                                type: 'javascript',
                                payload: `
                                    jQuery('.minicart-quantity').text('${parsedResponse.cart.numItems}').removeClass('d-none');
                                    jQuery('body').trigger('product:afterAddToCart');
                                    if (jQuery(window).width() >= 770) {
                                        jQuery('.minicart .minicart-link').trigger('click');
                                    }
                                    else {
                                        var i = {
                                            class: "fade modal modal-auto modal-center mobile-minicart-modal mobile-banner-top",
                                            id: "mobile-minicart-modalId",
                                            verticalCenter: !0,
                                            modalTitle: "<span></span>",
                                            modalBody: "${escapedHtml}"
                                        }
                                        $("body").trigger("initModal", i), $("#mobile-minicart-modalId").modal("show"), $("#mobile-minicart-modalId .modal-content").css("height", ""), setTimeout((function() {
                                            $("#mobile-minicart-modalId").modal("hide")
                                        }), 3e3)
                                    }
                                `,
                            },
                        ])
                        return callback(true)
                    },
                    params.length * 2000
                )
            },
            2000
        )
    }
    // ---- Lululemon ios ----
    else if (inWKWebView() && ['lululemon.com'].indexOf(getQueryValue('domain')) !== -1) {
        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize, from } = one

            // ---- Recuperation du variation_id ----
            let offer_ids = []
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.offer_id !== 'undefined') {
                    if (size.sibling_offer_ids) {
                        offer_ids = size.sibling_offer_ids
                    } else {
                        offer_ids.push(size.offer_id)
                    }
                }
            }

            // ---- Envoi de l'event ----
            offer_ids.forEach((offer_id) => {
                sendWKScriptMessage('bridge', {
                    event_id: 'add_to_cart',
                    productId: garment.product_external_id,
                    sku: offer_id,
                    quantity: 1,
                    location: from == 'Item Detail' ? 'pdp' : 'sheet',
                })
            })
        }

        // ---- On attend un peu avant de lancer la suite ----
        setTimeout(() => callback(true), 1000)
    }
    // ---- Afibel ----
    else if (inIframe() && getQueryValue('domain') == 'afibel.com') {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Recuperation du variation_id ----
            let offer_size = null
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.offer_id !== 'undefined') {
                    offer_size = size
                }
            }

            // ---- Url en fonction de l'origin ----
            let postUrl = '/fr/api/checkout/cart/add'
            if (origin) {
                const match = origin.match(/^https?:\/\/[^/]+\/([^/]+)\//)
                if (match) {
                    postUrl = '/' + match[1] + '/api/checkout/cart/add'
                }
            }

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: postUrl,
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/javascript',
                        'X-Requested-With': 'XMLHttpRequest',
                        'X-AUTH-TOKEN':
                            '1e15fe7cac63678de86d7e716c847933a82ac45462cc96acd583bad441794733',
                    },
                    dataType: 'json',
                    data: {
                        catalogSku: 'CE' + garment.product_external_id,
                        sku: offer_size.offer_id,
                        sizeLabel: offer_size.label,
                        qty: '1',
                    },
                },
            })
        }

        // ---- Lancement du message avec callback ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            payloads,
            (responses) => {
                if (responses === false) {
                    return callback(false)
                }
                const response = responses.pop()
                const parsedResponse = JSON.parse(response)
                if (!parsedResponse || !parsedResponse.status || !parsedResponse.nbProducts) {
                    return callback(false)
                }
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `document.dispatchEvent(new Event('refreshCart'));`,
                    },
                ])
                return callback(true)
            },
            params.length * 1000
        )

        return callback(true)
    }
    // ---- Adoreme ----
    else if (inIframe() && getQueryValue('api_prefix') == '/v1/adoreme') {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        const filters = []
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Construction des group ----
            const groupValues = {}
            for (const oneSize of currentSize) {
                const group = oneSize.group || 'defaults'
                if (!groupValues[group]) {
                    groupValues[group] = {}
                }
                groupValues[group][oneSize.field] = oneSize.value
                if (oneSize.filter && Array.isArray(oneSize.filter)) {
                    for (const filter of oneSize.filter) {
                        if (filters.indexOf(filter) === -1) {
                            filters.push(filter)
                        }
                    }
                }
            }

            // ---- Recuperation du filtre unique ----
            const filter = filters.length === 1 ? filters.pop() : null

            // ---- Recuperation des group offers ----
            const groupOffers = {}
            for (const group in groupValues) {
                const groupFilter = groupValues[group]
                for (const onePossible of garment.product_sizes) {
                    const groupName = onePossible.name || 'defaults'
                    if (groupName != group) {
                        continue
                    }
                    for (const groupOffer of onePossible.possible_values) {
                        if (
                            filter !== null &&
                            (!Array.isArray(groupOffer.filters) ||
                                !groupOffer.filters.includes(filter))
                        ) {
                            continue
                        }
                        let found = true
                        for (const key in groupFilter) {
                            const value = groupFilter[key]
                            if (groupOffer[key] !== value) {
                                found = false
                                break
                            }
                        }
                        if (found === true) {
                            groupOffers[group] = groupOffer
                            break
                        }
                    }
                }
            }

            // ---- Ajout du payload ----
            const payload = {
                amid: garment.product_external_id,
                sizes: {},
                quantity: 1,
                productType: garment['product_external_type'],
            }
            for (const group in groupOffers) {
                const offer = groupOffers[group]
                const groupLabel = group.substring(0, group.length - 1)
                payload.sizes[groupLabel] = offer.offer_id.toLowerCase()
                payload.amid = offer.id
            }
            payloads.push(payload)

            // ---- Envoi de l'event ----
            sendIframeMessage('veesual_execute', [
                {
                    type: 'javascript',
                    payload: `window.dispatchEvent(new CustomEvent('addToCart', {detail: JSON.parse('${JSON.stringify(
                        payloads
                    ).replace(/'/, "\\'")}')}));`,
                },
            ])
        }

        return callback(true)
    }
    // ---- Alme ----
    else if (inIframe() && getQueryValue('api_prefix') == '/v1/alme') {
        // ---- Initialisation ----
        const payloads = []

        // ---- Configuration de la section ----
        const responseSection = 'sections--22689187758390__cart-drawer'

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Recuperation du variation_id ----
            let offer = null
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.offer_id !== 'undefined') {
                    offer = size
                }
            }

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: '/cart/add',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/javascript',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    dataType: 'json',
                    data: {
                        'Taille-1': offer.label,
                        form_type: 'product',
                        utf8: '✓',
                        id: offer.offer_id,
                        'product-id': garment.product_external_id,
                        'section-id': 'template--22689188643126__main',
                        sections: responseSection,
                        sections_url: window.location.pathname,
                    },
                },
            })
        }

        // ---- Lancement du message avec callback ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            payloads,
            (responses) => {
                if (responses === false) {
                    return callback(false)
                }
                const response = responses.pop()
                let escapedHtml = JSON.parse(response)
                if (!escapedHtml.sections || !escapedHtml.sections[responseSection]) {
                    return callback(false)
                }
                escapedHtml = JSON.stringify(String(escapedHtml.sections[responseSection]))
                escapedHtml = escapedHtml.substring(1, escapedHtml.length - 1)
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `
                            (function(){
                                const tmpSectionParser = document.createElement('body');
                                tmpSectionParser.innerHTML = "${escapedHtml}";
                                document.getElementById('CartCount').innerHTML = tmpSectionParser.querySelector('template').content.querySelector('#NewCartCount').innerHTML;
                            })();
                        `,
                    },
                ])
                return callback(true)
            },
            params.length * 5000
        )

        return callback(true)
    }
    // ---- Default ----
    else {
        setTimeout(() => callback(true), 2000)
    }
}

export const addGarmentSizeToCart = (
    garment: Models.Garment,
    currentSize: string | Models.MultiSize[],
    from: string = null,
    callback: (success: boolean) => void
) => {
    addGarmentSizesToCart([{ garment, currentSize, from }], callback)
}

// ---- Remove the current type garment on the look ----
export const removeGarment = (removeType?: string) => {
    // ---- Var init ----
    const state = store.getState()
    const garmentType = state.garment.type
    const lookRequest = state.look.request
    const lowerType = garmentType.toLowerCase()
    // ---- Lowercase the input to be sure as we need it in lowercase for the look object ----
    const lowerRemoveType = removeType?.toLowerCase()

    // ---- Do nothing if the garment is already removed ----
    if (!lookRequest[lowerRemoveType || lowerType]) {
        return
    }

    const cloneLookRequest = JSON.parse(JSON.stringify(lookRequest))
    cloneLookRequest[lowerRemoveType || lowerType] = null

    store.dispatch(
        HandleLookRequest({
            lookRequest: cloneLookRequest,
            keepAdditional: true,
            focus: garmentType,
        })
    )

    // ---- If we are in mobile we shrink the drawer ----
    if (window.innerWidth / window.innerHeight < 1 && state.layoutSlice.drawer !== 'small') {
        store.dispatch(shrinkDrawer())
    }
}

// ---- Get the boolean used to narrow down product sizes types (true = GarmentSizeGroup) ----
export const getIsSizeGroup = (productSizes: Models.OldGarmentSize[] | Models.GarmentSize[]) => {
    return productSizes.length > 0 && 'type' in productSizes[0]
}
