import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useMemo } from 'react'
import { useAppSelector } from 'src/store'
import { getQueryValue } from 'src/utils/query'
import { trackEvent } from 'src/utils/tracking'

interface ExitButtonProps {
    hideMobile?: boolean
    category?: string
}

export default function ExitButton(props: ExitButtonProps) {
    const defaultReferrer = useAppSelector((state) => state.profile?.company?.default_referrer)
    const { hideMobile, category } = props

    const referrerValue = useMemo(() => {
        const queryReferrer = getQueryValue('referrer')
        if (queryReferrer && queryReferrer.match(/https?:\/\//)) {
            return queryReferrer
        }
        return defaultReferrer
    }, [defaultReferrer])

    const handleOnClick = () => {
        trackEvent('Exit Clicked', {}, category || 'Menu')
    }

    return (
        <>
            {referrerValue && (
                <Button
                    type='text'
                    className={`layout--header-burger-button ${
                        hideMobile ? 'button--desktop' : ''
                    }`}
                    icon={<ArrowLeftOutlined />}
                    onClick={handleOnClick}
                    href={referrerValue}
                    target={'_parent'}
                />
            )}
        </>
    )
}
