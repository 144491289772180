import React from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useAppSelector } from 'src/store'
import { useLocation } from 'react-router-dom'
import ExitButton from '../button/ExitButton'
import { Button, theme } from 'antd'
import ExperienceTabs from '../ExperienceTabs'
import { useDispatch } from 'react-redux'
import { toggleFavoritesModal } from 'src/store/slices/layoutSlice'
import { getFavoriteIcon } from 'src/utils/icon'
import useCustomTranslation from 'src/utils/translation'

const { useToken } = theme

interface HeaderMainContentProps {
    onBackClick: () => void
}

export default function HeaderMainContent(props: HeaderMainContentProps) {
    const location = useLocation()
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const { token } = useToken()

    const company = useAppSelector((state) => state.profile?.company)
    const favorites = useAppSelector((state) => state.favorites)

    const { onBackClick } = props

    return (
        <div
            className={`layout--header-main-container ${
                company.garment_category_facets ? 'layout--header-title-separator' : ''
            }`}
        >
            <div className='layout--header-top-container'>
                <div className='layout--header-left'>
                    <div className='layout--header-left-content'>
                        <div className='layout--header-left-content-menu'>
                            <ExperienceTabs className='show-desktop' />
                        </div>
                    </div>
                </div>

                <div className='layout--header-right'>
                    <div className='button--mobile'>
                        {location.pathname === '/swipe' ? (
                            <ExitButton />
                        ) : (
                            <Button
                                onClick={onBackClick}
                                icon={<ArrowLeftOutlined />}
                                type='text'
                                className='button layout--header-burger-button'
                            />
                        )}
                    </div>
                    <div className='layout--title-mobile'>{t('layout.title')}</div>
                    <ExperienceTabs className='show-mobile' />
                    {company.enable_wishlist && (
                        <Button
                            className='layout--header-favorite-button'
                            onClick={() => dispatch(toggleFavoritesModal())}
                            type='text'
                            icon={getFavoriteIcon(
                                favorites.garments.length > 0 || favorites.looks.length > 0
                                    ? 'filled'
                                    : 'outlined',
                                token.colorPrimary
                            )}
                        >
                            {t(
                                `layout.my_favorites${
                                    window.innerWidth / window.innerHeight < 1 ? '_mobile' : ''
                                }`
                            )}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}
