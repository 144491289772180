import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import parse from 'html-react-parser'

import ImageSmooth from 'src/components/image/ImageSmooth'

import { resizeImage } from 'src/utils/image'
import { formattedPrice } from 'src/utils/price'
import { trackEvent } from 'src/utils/tracking'
import { ChangeShowFiltersAction } from 'src/store/actions/filters'
import { HandleLookRequest } from 'src/store/actions/look'
import { Button, Card, Row, Tooltip, Typography } from 'antd'
import { useAppSelector } from 'src/store'
import { shrinkDrawer } from 'src/store/slices/layoutSlice'
import { getColorIcon } from 'src/utils/icon'
import { addVariantFilter } from 'src/utils/variantHelpers'
import useCustomTranslation from 'src/utils/translation'
import { getCurrentActiveFiltersForType } from 'src/store/slices/databaseSlice'
import FavoriteButton from '../button/FavoriteButton'
import { CATALOG } from 'src/settings/global'
import StartingAt from '../StartingAt'
import useGarmentMethodsHook from 'src/utils/garmentMethodsHook'

const { Title, Paragraph } = Typography

interface CardItemProps {
    data: Models.Garment
    ratio: number
}

const CardItem: React.FunctionComponent<CardItemProps> = (props) => {
    const { data, ratio } = props

    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const { convertedPrice } = useGarmentMethodsHook(data)

    const internal = useAppSelector((state) => state.profile?.company?.internal)
    const priceFloat = useAppSelector((state) => state.profile?.company?.price_float)
    const useClipping = useAppSelector((state) => state.profile?.company?.use_clipping)
    const layoutSlice = useAppSelector((state) => state.layoutSlice)
    const garmentType = useAppSelector((state) => state.garment?.type)
    const activeFilters = useAppSelector((state) =>
        getCurrentActiveFiltersForType(state, garmentType)
    )
    const garment = useAppSelector((state) =>
        state.look.request ? state.look.request[data.garment_type.toLowerCase()] : null
    )

    const activeVariantValues = activeFilters['garment_variant_ids']

    const isSelected = garment?.garment_id === data?.garment_id

    const garmentPrice = convertedPrice?.product_price || data.product_price
    const garmentOriginalPrice =
        convertedPrice?.product_price_original || data.product_price_original
    const garmentCurrency = convertedPrice?.product_currency || data.product_currency

    const isPromotion = useMemo(() => {
        if (data) {
            return !!garmentOriginalPrice && garmentOriginalPrice > garmentPrice
        }

        return false
    }, [data, garmentPrice, garmentOriginalPrice])

    const imageUrl =
        useClipping && data.image_clipping_url ? data.image_clipping_url : data.image_url

    const handleCardClick = () => {
        trackEvent('Item Selected', [data, { catalog_mode: layoutSlice.drawer }], 'Catalog')
        dispatch(
            HandleLookRequest({
                lookRequest: {
                    [data.garment_type.toLowerCase()]: data,
                },
                keepAdditional: true,
                focus: data.garment_type,
                from: CATALOG,
            })
        )
        dispatch(ChangeShowFiltersAction(false))

        // ---- If we are in mobile we shrink the drawer ----
        if (window.innerWidth / window.innerHeight < 1 && layoutSlice.drawer !== 'small') {
            dispatch(shrinkDrawer())
        }
    }

    const handleColorClick = () => {
        trackEvent('Variant Clicked', data, 'Catalog')

        addVariantFilter(data.garment_id)
    }

    return (
        <Card
            id={data.garment_id}
            className={
                'card card--container override_card_container override_card_catalog_container card--without-description' +
                (data.product_brand ? ' card--with-brand' : '') +
                (isSelected ? ' card--selected' : '')
            }
            hoverable
            onClick={handleCardClick}
            cover={
                <div className='card card--image-container'>
                    <div className={'override_card_cover_catalog card--image'}>
                        <ImageSmooth
                            className='card--background'
                            src={resizeImage(imageUrl, { width: 800 })}
                            ratio={ratio}
                            transition={false}
                        />
                    </div>
                    {data.garment_has_variants && !activeVariantValues && (
                        <Tooltip title={t('layout.multi_coloris')} trigger={'hover'}>
                            <Button
                                className='button--icon card--button-color'
                                icon={getColorIcon()}
                                onClick={handleColorClick}
                            />
                        </Tooltip>
                    )}
                </div>
            }
        >
            {data.product_brand && (
                <Paragraph
                    ellipsis={{
                        rows: 2,
                    }}
                    className='text text--center text--small card--text override_card_brand'
                >
                    {parse(data.product_brand)}
                </Paragraph>
            )}
            {data.product_name && (
                <Title
                    ellipsis={{ rows: 3 }}
                    className='title title--h3 title--center card--title override_card_title'
                >
                    {parse(data.product_name)}
                </Title>
            )}
            {data.product_subname && (
                <Paragraph
                    ellipsis={{
                        rows: 2,
                    }}
                    className='text text--center text--small card--subname'
                >
                    {parse(data.product_subname)}
                </Paragraph>
            )}
            {data.product_price && (
                <Row className='card--price--container'>
                    <StartingAt garment={data} style={{ textAlign: 'center' }} />
                    {isPromotion && (
                        <Title
                            ellipsis={{
                                rows: 1,
                            }}
                            className='title title--center card--price--promotion'
                        >
                            <span className='card--price--original'>
                                {convertedPrice && convertedPrice.product_price_original_string
                                    ? convertedPrice.product_price_original_string
                                    : formattedPrice(
                                          garmentOriginalPrice,
                                          garmentCurrency,
                                          priceFloat
                                      )}
                            </span>
                            {internal === 'gemofr' ? (
                                <span className='card--price--percent'>PROMO</span>
                            ) : (
                                <span className='card--price--percent'>
                                    -
                                    {Math.round(
                                        ((garmentOriginalPrice - garmentPrice) * 100) /
                                            garmentOriginalPrice
                                    )}
                                    %
                                </span>
                            )}
                        </Title>
                    )}
                    <Title
                        ellipsis={{
                            rows: 1,
                        }}
                        className={`title title--center title--h2 card--title card--price--final${
                            isPromotion ? ' card--price--final--promotion' : ''
                        }`}
                    >
                        {convertedPrice && convertedPrice.product_price_string
                            ? convertedPrice.product_price_string
                            : formattedPrice(garmentPrice, garmentCurrency, priceFloat)}
                    </Title>
                </Row>
            )}

            <FavoriteButton data={data} className='card--favorite-button' eventCategory='Catalog' />
        </Card>
    )
}

export default CardItem
