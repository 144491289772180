import { loadFromLocalStorageArray, saveIntoLocalStorage } from 'src/utils/localStorageHelpers'
import {
    CHANGE_STORAGE_BASE_KEY,
    HANDLE_ADD_TO_FAVORITES_ITEMS,
    HANDLE_ADD_TO_FAVORITES_OUTFIT,
    HANDLE_REMOVE_TO_FAVORITES_ITEMS,
    HANDLE_REMOVE_TO_FAVORITES_OUTFIT,
    SWITCH_FAVORITES_CONTENT,
} from '../actions'

import { getQueryValue } from 'src/utils/query'

const domain = getQueryValue('domain') || 'undefined'
const experienceId = getQueryValue('experience_id')
const storageBaseKey = domain + (experienceId ? '_' + experienceId : '')

export const initialState: State.Favorites = {
    looks: loadFromLocalStorageArray(`${storageBaseKey}.favorites.looks`),
    garments: loadFromLocalStorageArray(`${storageBaseKey}.favorites.garments`),
    isOutfit: false,
    storageBaseKey: storageBaseKey,
}

const GarmentReducers = (
    state: State.Favorites = initialState,
    action: Types.Action
): State.Favorites => {
    switch (action.type) {
        case HANDLE_ADD_TO_FAVORITES_ITEMS:
            return {
                ...state,
                garments: saveIntoLocalStorage(
                    `${state.storageBaseKey}.favorites.garments`,
                    action.payload
                ),
                isOutfit:
                    window.location.pathname === '/favorites' && state.looks.length
                        ? state.isOutfit
                        : false,
            }
        case HANDLE_REMOVE_TO_FAVORITES_ITEMS:
            return {
                ...state,
                garments: saveIntoLocalStorage(
                    `${state.storageBaseKey}.favorites.garments`,
                    action.payload
                ),
                isOutfit: window.location.pathname === '/favorites' ? state.isOutfit : false,
            }

        case HANDLE_ADD_TO_FAVORITES_OUTFIT:
            return {
                ...state,
                looks: saveIntoLocalStorage(
                    `${state.storageBaseKey}.favorites.looks`,
                    action.payload
                ),
                isOutfit:
                    window.location.pathname === '/favorites' && state.garments.length
                        ? state.isOutfit
                        : true,
            }
        case HANDLE_REMOVE_TO_FAVORITES_OUTFIT:
            return {
                ...state,
                looks: saveIntoLocalStorage(
                    `${state.storageBaseKey}.favorites.looks`,
                    action.payload
                ),
                isOutfit: window.location.pathname === '/favorites' ? state.isOutfit : true,
            }

        case SWITCH_FAVORITES_CONTENT:
            return {
                ...state,
                isOutfit: action.payload,
            }

        case CHANGE_STORAGE_BASE_KEY:
            return {
                ...initialState,
                looks: loadFromLocalStorageArray(
                    `${storageBaseKey}.${action.payload}.favorites.looks`
                ),
                garments: loadFromLocalStorageArray(
                    `${storageBaseKey}.${action.payload}.favorites.garments`
                ),
                storageBaseKey: `${storageBaseKey}.${action.payload}`,
            }

        default:
            return state
    }
}

export default GarmentReducers
