import { Tabs } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseButton from 'src/components/button/CloseButton'

import FavoritesCatalog from 'src/containers/catalog/FavoritesCatalog'
import { SwitchFavoritesContentAction } from 'src/store/actions/favorites'
import { toggleFavoritesModal } from 'src/store/slices/layoutSlice'

import { trackPage } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'

const FavoritesPage: React.FunctionComponent = () => {
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const favorites = useSelector((state: State.Root) => state.favorites)

    useEffect(() => {
        trackPage()
        // eslint-disable-next-line
    }, [favorites.isOutfit])

    return (
        <div className='favorites--container'>
            <CloseButton
                className='modal--close'
                onClick={() => dispatch(toggleFavoritesModal())}
            />
            <div className='favorites--title'>{t('favorite.title')}</div>
            <Tabs
                activeKey={favorites.isOutfit ? '1' : '0'}
                className={`tabs--experience`}
                items={[
                    { label: t('favorite.saved_items'), key: '0' },
                    { label: t('favorite.saved_outfits'), key: '1' },
                ]}
                onTabClick={(activeKey) =>
                    dispatch(SwitchFavoritesContentAction(activeKey === '1'))
                }
            />
            <div className='favorites--catalog-container'>
                <FavoritesCatalog />
            </div>
        </div>
    )
}

export default FavoritesPage
