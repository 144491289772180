import { combineReducers } from 'redux'

import model from './modelProduct'
import garment from './garment'
import look from './look'
import profile from './profile'
import modal from './modal'
import loader from './loader'
import favorites from './favorites'
import filters from './filters'
import sidebar from './sidebar'
import swipe from './swipe'
import error from './error'
import { apiGarment } from '../api/api-garment'
import databaseSlice from '../slices/databaseSlice'
import { apiExperience } from '../api/api-experience'
import layoutSlice from '../slices/layoutSlice'
import { apiLook } from '../api/api-look'
import loadingMonitoringSlice from '../slices/loadingMonitoringSlice'
import { apiPrice } from '../api/api-price'

export default combineReducers({
    model,
    garment,
    look,
    profile,
    modal,
    loader,
    favorites,
    filters,
    sidebar,
    swipe,
    error,
    [apiGarment.reducerPath]: apiGarment.reducer,
    [apiExperience.reducerPath]: apiExperience.reducer,
    [apiLook.reducerPath]: apiLook.reducer,
    [apiPrice.reducerPath]: apiPrice.reducer,
    databaseSlice: databaseSlice,
    layoutSlice: layoutSlice,
    loadingMonitoringSlice: loadingMonitoringSlice,
})
