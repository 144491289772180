import { Button } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'src/store'
import {
    AddFavoritesItemsAction,
    AddFavoritesOutfitAction,
    RemoveFavoritesItemsAction,
    RemoveFavoritesOutfitAction,
} from 'src/store/actions/favorites'
import { isGarmentInFavorites, isLookInFavorites } from 'src/utils/favorite'
import { getFavoriteIcon } from 'src/utils/icon'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'
import { addGarmentToWishlist, removeGarmentFromWishlist } from 'src/utils/wishlist'

interface FavoriteButtonProps {
    data?: Models.Garment | Models.Look
    textLeft?: string
    textRight?: string
    className?: string
    eventCategory?: string
}

/**
 * Button used to add or remove a Garment or a Look from the favorites
 * @param props the Garment or the Look and text on left or right as optional
 * @returns
 */
export default function FavoriteButton(props: FavoriteButtonProps) {
    const dispatch = useDispatch()
    const favorites = useAppSelector((state) => state.favorites)
    const company = useAppSelector((state) => state.profile.company)
    const { t } = useCustomTranslation()

    const { data, textLeft, textRight, className, eventCategory = 'Favorite' } = props

    // ---- We need data to show the component ----
    if (!data) {
        return null
    }

    // ---- Type guard function ----
    function isLook(localData: Models.Garment | Models.Look): localData is Models.Look {
        return (localData as Models.Look)?.look_id !== undefined
    }

    const inFavorites = isLook(data)
        ? isLookInFavorites(favorites.looks, data)
        : isGarmentInFavorites(favorites.garments, data)

    const addOnFavorites = (current: Models.Garment | Models.Look) => {
        if (isLook(data)) {
            dispatch(AddFavoritesOutfitAction([...favorites.looks, current] as any))

            return
        }

        dispatch(AddFavoritesItemsAction([...favorites.garments, current] as any))
    }

    const removeFromFavorites = (current: Models.Garment | Models.Look) => {
        const favoritesGarments = [...favorites.garments]
        const favoritesLooks = [...favorites.looks]
        if (isLook(current)) {
            const currentIndex = favoritesLooks.findIndex(
                (item) => item.look_id === current.look_id
            )
            if (currentIndex >= 0) favoritesLooks.splice(currentIndex, 1)
            dispatch(RemoveFavoritesOutfitAction(favoritesLooks))

            return
        }

        const currentIndex = favoritesGarments.findIndex(
            (item) => item.garment_id === current.garment_id
        )
        if (currentIndex >= 0) favoritesGarments.splice(currentIndex, 1)
        dispatch(RemoveFavoritesItemsAction(favoritesGarments))
    }

    const handleAddToFavorite = (e) => {
        e.stopPropagation()
        if (isLook(data)) {
            trackEvent('Look Saved to favorite', data, eventCategory)
            addOnFavorites(data)
        } else {
            addGarmentToWishlist(data, (success: boolean) => {
                trackEvent(
                    'Item Saved to favorite',
                    [data, { callback_success: success }],
                    eventCategory
                )
                if (success) {
                    addOnFavorites(data)
                } else {
                    alert(t('error.favorite'))
                }
            })
        }
    }

    const handleRemoveFromFavorite = (e) => {
        e.stopPropagation()
        if (isLook(data)) {
            trackEvent('Look Removed from favorite', data, eventCategory)
            removeFromFavorites(data)
        } else {
            removeGarmentFromWishlist(data, (success: boolean) => {
                // ---- On retire quand meme en cas d'erreur ----
                trackEvent(
                    'Item Removed from favorite',
                    [data, { callback_success: success }],
                    eventCategory
                )
                removeFromFavorites(data)
            })
        }
    }

    // ---- Only show the button if we have the whishlist enabled ----
    if (!company.enable_wishlist) {
        return null
    }

    return (
        <div className={className}>
            <Button
                onClick={inFavorites ? handleRemoveFromFavorite : handleAddToFavorite}
                type='text'
                className='button--favorite'
                prefix='test'
            >
                {window.innerWidth / window.innerHeight >= 1 && textLeft}
                {getFavoriteIcon(inFavorites ? 'filled' : 'outlined')}
                {window.innerWidth / window.innerHeight >= 1 && textRight}
            </Button>
        </div>
    )
}
