import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Image } from 'antd'
import useCustomTranslation from 'src/utils/translation'
import { trackEvent } from 'src/utils/tracking'
import { useDispatch } from 'react-redux'
import { toggleModelModal } from 'src/store/slices/layoutSlice'
import { getUserModel, saveUserModel } from 'src/utils/localStorageHelpers'
import { useAppSelector } from 'src/store'
import { resizeImage } from 'src/utils/image'
import Slider from 'react-slick'

export default function ModelTile() {
    const { t } = useCustomTranslation()
    const dispatch = useDispatch()

    const lookCurrent = useAppSelector((state) => state.look.current)
    const models = useAppSelector((state) => state.model.all)
    const modelModal = useAppSelector((state) => state.layoutSlice.modelModal)

    const [hasStoredModel, setHasStoredModel] = useState<boolean>(!!getUserModel())
    const [swipeCounter, setSwipeCounter] = useState<number>(0)

    const sliderRef = useRef(null)

    const slidesModel = useMemo(() => {
        if (!models || models.items.length === 0) {
            return []
        }

        // ---- Put current model at the beggining of the array so we can always start at 0 ----
        const clonedModels = JSON.parse(JSON.stringify(models))
        const sortedModels = clonedModels.items.sort((a, b) => {
            if (a.model_id === lookCurrent.model.model_id) {
                return -1
            }

            if (b.model_id === lookCurrent.model.model_id) {
                return 1
            }

            return 0
        })

        // ---- We duplicate it to make 2 full rotation when animated----
        const result = [...sortedModels, ...sortedModels]

        // ---- Add lookCurrent's model so we have the clean picture without offset as the last element ----
        result.push(lookCurrent.model)

        return result
    }, [models, lookCurrent])

    const autoplayValue = useMemo(() => {
        // ---- If we have a stored model or if we looped once already we stop the autoplay ----
        if (hasStoredModel || swipeCounter >= slidesModel.length - 1) {
            return false
        }

        return true
    }, [hasStoredModel, swipeCounter, slidesModel])

    const handleModelClick = () => {
        trackEvent(
            'Choose model Clicked',
            [lookCurrent, { has_stored_model: hasStoredModel }],
            'Style Bar'
        )
        dispatch(toggleModelModal())

        // ---- Add current model in local storage if it doesn't exist ----
        if (!hasStoredModel) {
            saveUserModel(lookCurrent.model)
            setHasStoredModel(true)
        }
    }

    // ---- Need this useEffect so the tile is updated after we close the model modal (and the modal was not opened by the tile) ----
    useEffect(() => {
        // ---- We only handle when we close the modal ----
        if (modelModal) {
            return
        }

        const hasUserModel = !!getUserModel()

        if (hasUserModel !== hasStoredModel) {
            setHasStoredModel(hasUserModel)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelModal])

    const settings = {
        initialSlide: 0,
        centerMode: true,
        infinite: true,
        draggable: false,
        swipe: false,
        centerPadding: '0px',
        slidesToShow: 1,
        speed: 500,
        arrows: false,
        className: 'model--tile-carousel--container',
        autoplay: autoplayValue,
        autoplaySpeed: 3000,
        beforeChange: () => {
            setSwipeCounter(swipeCounter + 1)
        },
    }

    return (
        <div
            onClick={handleModelClick}
            className={`style-bar--element style-bar--chose-model${
                !hasStoredModel ? ' style-bar--chose-model-first' : ''
            }`}
        >
            {hasStoredModel ? (
                <div className={`style-bar--image-container style-bar--image-container-zoom`}>
                    <Image
                        preview={false}
                        src={resizeImage(lookCurrent.model.image_url, {
                            width: 800,
                        })}
                        className='style-bar--image-zoom'
                        rootClassName='style-bar--root-image'
                        draggable={false}
                    />
                </div>
            ) : (
                <div className={`style-bar--image-container style-bar--image-container-zoom`}>
                    <Slider {...settings} ref={sliderRef}>
                        {slidesModel.map((model) => (
                            <React.Fragment key={model.model_id}>
                                <Image
                                    preview={false}
                                    src={resizeImage(model.image_url, {
                                        width: 800,
                                    })}
                                    className={'style-bar--image-zoom'}
                                    rootClassName='style-bar--root-image'
                                    draggable={false}
                                />
                            </React.Fragment>
                        ))}
                    </Slider>
                </div>
            )}

            <div className='style-bar--title'>
                {hasStoredModel ? t('look.change_again_model') : t('look.change_model')}
            </div>
        </div>
    )
}
