import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { trackEvent, trackPage } from 'src/utils/tracking'
import Loader from 'src/components/Loader'
import CarouselGarment from 'src/components/carousel/carouselGarment'
import {
    HandleLookRequest,
    SetGarmentHistory,
    SetLookIndexAction,
    SetUserTuckAction,
} from 'src/store/actions/look'
import { getAllGarments } from 'src/store/slices/databaseSlice'
import store, { useAppSelector } from 'src/store'
import useCustomTranslation from 'src/utils/translation'
import useCustomGetGarments from 'src/utils/custom-getGarments-hook'
import useTypeFunctions from 'src/utils/typeMethods-hook'
import { Button, Checkbox, Row, Tooltip } from 'antd'
import { getQueryValue } from 'src/utils/query'
import Lightbox, { Plugin } from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import { expandCartDrawer, expandRightPart } from 'src/store/slices/layoutSlice'
import LookContainer from 'src/containers/look/Look'
import FavoriteButton from 'src/components/button/FavoriteButton'
import { getContractIcon, getExpandIcon, getInfoIcon, getZoomIcon } from 'src/utils/icon'
import useShowHideHeader from 'src/utils/showHideHeader'
import { formattedHeight } from 'src/utils/height'
import PoweredBy from 'src/components/PoweredBy'

const TOP = 'TOP'
const BOTTOM = 'BOTTOM'
const OUTERWEAR = 'OUTERWEAR'

const SwipePage: React.FunctionComponent = () => {
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const { isTypeAsDress, lookHasDressType } = useTypeFunctions()
    const { showHideHeader } = useShowHideHeader()

    const garmentType = useAppSelector((state) => state.garment?.type)
    const allGarments = useAppSelector((state) => getAllGarments(state))
    const look = useAppSelector((state) => state.look?.request)
    const lookIndex = useAppSelector((state) => state.look?.index)
    const company = useAppSelector((state) => state.profile.company)
    const layoutSlice = useAppSelector((state) => state.layoutSlice)
    const headerHidden = useAppSelector((state) => state.profile.headerHidden)

    const [getGarmentsTrigger, { error }] = useCustomGetGarments()

    const zoomRef = useRef(null)

    const swipeRef = useRef<HTMLDivElement>(null)
    const [swipeHeight, setSwipeHeight] = useState<number>(0)
    const [zoomVisible, setZoomVisible] = useState(false)
    const [isSlideOverlapBottom, setIsSlideOverlapBottom] = useState<boolean>()

    const domain = getQueryValue('domain')
    const hideRightPart = getQueryValue('hide_cart')
    const withInfo = !domain || domain !== 'marinehenrion.com'

    const handleResize = () => {
        setSwipeHeight(swipeRef?.current?.clientHeight)

        // ---- Handle overlapping bottom left ----
        const slideElements = document.getElementsByClassName('swipe--carousel')
        const bottomLeftElem = document.getElementsByClassName('card-model--bottom-left')[0]
        if (bottomLeftElem && slideElements && slideElements.length > 0) {
            const bottomLeftElemRect = bottomLeftElem.getBoundingClientRect()
            let localOverlap = false

            // ---- For each carousel we check if it overlaps with the bottomLeftElement ----
            for (let i = 0; i < slideElements.length; i++) {
                const slideRect = slideElements[i].getBoundingClientRect()

                // ---- If it does, we update the state and the local var then we break from the loop ----
                if (slideRect.y + slideRect.height >= bottomLeftElemRect.y) {
                    setIsSlideOverlapBottom(true)
                    localOverlap = true
                    break
                }
            }

            // ---- We set back the value to false if we did not have an overlap ----
            if (!localOverlap) {
                setIsSlideOverlapBottom(false)
            }
        }
    }

    const handleZoomClick = (e) => {
        e.stopPropagation()
        trackEvent('Zoom Opened', [look, { outfit_index_selected: lookIndex }], 'Outfit')
        setZoomVisible(true)
    }

    const handlePoweredClick = () => {
        trackEvent('Powered Clicked', [look, { outfit_index_selected: lookIndex }], 'Outfit')
    }

    const handleChangeTuck = (e) => {
        const newTuckValue = e.target.checked ? 'tuck' : 'untuck'
        trackEvent('Tuck in Changed', [look, { tuck_value: newTuckValue }], 'Outfit')
        dispatch(
            HandleLookRequest({
                lookRequest: { mode: newTuckValue },
                keepAdditional: true,
            })
        )

        // ---- We store the value chosen by the user to keep as default for look requests ----
        dispatch(SetUserTuckAction(newTuckValue))
    }

    const handleBackClick = (index: number, e?: React.MouseEvent) => {
        e?.stopPropagation()
        trackEvent(
            'Back Changed',
            [look, { back_value: index === 1 ? 'on' : 'off', back_type: 'toggle' }],
            'Outfit'
        )
        dispatch(SetLookIndexAction(index))
    }

    const handleOpenZoom = () => {
        // ---- Var init ----
        const margin = 16
        const zoomValue = 1.5
        const screenHeight = window.innerHeight

        // ---- Offset according to the carousel top and zoomvalue (positif or negative according to center) ----
        const offsetY =
            ((screenHeight - margin * 2) * 1.5 - screenHeight) *
            (company[`swipe_${garmentType.toLowerCase()}_ratio`]?.top < 0.5 ? -1 : 1)

        // ---- Call zoom with correct params ----
        zoomRef.current.changeZoom(zoomValue, true, 0, offsetY)
    }

    // ---- array of slides containing image data used by the lightbox ----
    const imagesSlides = useMemo(() => {
        if (!look) {
            return []
        }
        return Array.from(look.image_urls, (imageUrl) => {
            return { src: imageUrl }
        })
    }, [look])

    const tuckDisabled = useMemo(() => {
        return !look || !company || lookHasDressType(look) || look.mode_switch === false
    }, [look, company, lookHasDressType])

    const showCarousel = useMemo(() => {
        // ---- Mobile condition ----
        if (window.innerWidth / window.innerHeight < 1) {
            return (
                !layoutSlice.hideSwipe &&
                (layoutSlice.drawer === 'none' || layoutSlice.drawer === 'small')
            )
        }

        // ---- Desktop & hideRightPart ----
        if (hideRightPart) {
            return layoutSlice.leftPart === 'none' || layoutSlice.leftPart === 'medium'
        }

        // ---- Desktop Default ----
        return layoutSlice.leftPart === 'none'
    }, [layoutSlice, hideRightPart])

    const carouselLoading = useMemo(
        () =>
            !look ||
            !garmentType ||
            ([TOP, BOTTOM].indexOf(garmentType) !== -1 &&
                (!allGarments.TOP || !allGarments.BOTTOM)) ||
            !allGarments[garmentType],
        [garmentType, allGarments, look]
    )

    const info = useMemo(() => {
        // ---- Need look to get the infos ----
        if (!look) {
            return ''
        }

        // ---- Clone the model object ----
        const model = look.model
        const translationModelObject: Models.ModelProduct = JSON.parse(JSON.stringify(model))

        // ---- Edit the keys here as we can't do logic in the translation files ----
        translationModelObject.model_name = model.model_display || model.model_name
        translationModelObject.model_height = formattedHeight(
            model.model_height,
            company?.height_format
        )

        // ---- Use either the description or the translation with the new model object as param ----
        return model.model_description || t('model.description', { model: translationModelObject })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [look])

    useEffect(() => {
        trackPage()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!swipeRef.current) {
            return undefined
        }

        // ---- Observe firstImgRef for resize calls ----
        const resizeObserver = new ResizeObserver(() => {
            handleResize()
        })

        resizeObserver.observe(swipeRef.current)

        return () => {
            resizeObserver.disconnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [swipeRef])

    useEffect(() => {
        // ---- Need to check in interval because we only want this to happen once so we can't add data in useEffect dependency array ----
        const intervalId = setInterval(() => {
            // ---- State values ----
            const state = store.getState()
            const stateLook = state.look?.request
            const stateGarmentType = state.garment?.type
            const stateAllGarmentTypes = state.profile?.company?.garment_types
            const stateAllGarments = getAllGarments(state)
            const stateGarmentsHistory = state.look?.garmentsHistory

            if (!stateGarmentType || !stateLook) {
                return
            }

            clearInterval(intervalId)

            // We check if we have data for each garment types
            stateAllGarmentTypes.forEach((localType) => {
                if (
                    !stateAllGarments[localType] ||
                    (stateLook[localType.toLowerCase()] &&
                        !stateAllGarments[localType].all.find(
                            (garment) =>
                                garment.garment_id === stateLook[localType.toLowerCase()].garment_id
                        ))
                ) {
                    getGarmentsTrigger({
                        garment_id: stateLook[localType.toLowerCase()]?.garment_id,
                        type: localType,
                        page: 1,
                        init: 1,
                    })
                        .unwrap()
                        .then((resp) => {
                            // If we don't have history data yet we populate it with the first garment
                            if (!stateGarmentsHistory[localType] && resp && resp.items.length > 0) {
                                dispatch(
                                    SetGarmentHistory({ type: localType, garment: resp.items[0] })
                                )
                            }
                        })
                }
            })
        }, 100)

        return () => clearInterval(intervalId)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ---- Need to call handle Resize once after the carousel has loaded and that we show it to handle the overlap calcul ----
    useEffect(() => {
        if (carouselLoading || !showCarousel || isSlideOverlapBottom !== undefined) {
            return
        }

        handleResize()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carouselLoading, showCarousel])

    const handleBuyClick = () => {
        trackEvent('Buy Clicked', look, 'Outfit')
        dispatch(
            window.innerWidth / window.innerHeight < 1 ? expandCartDrawer() : expandRightPart()
        )
    }

    return (
        <>
            {error ? (
                <div>{t('error.error') + (error as API.ErrorQuery).data.message}</div>
            ) : (
                <div id='layoutSwipeContentId' className={`layout--swipe-content`} ref={swipeRef}>
                    {carouselLoading ? (
                        <div
                            style={{
                                height: '100%',
                            }}
                        >
                            <Loader />
                        </div>
                    ) : (
                        <>
                            {showCarousel && (
                                <>
                                    {[TOP, BOTTOM].indexOf(garmentType) !== -1 && (
                                        <>
                                            <div
                                                className={`swipe--carousel swipe--carousel-top swipe--carousel-TOP`}
                                                style={{
                                                    top: `${
                                                        (company[`swipe_top_ratio`]?.top || 0.25) *
                                                        100
                                                    }%`,
                                                }}
                                            >
                                                <CarouselGarment
                                                    type={TOP}
                                                    cardHeight={
                                                        swipeHeight *
                                                        (company[`swipe_top_ratio`]?.height || 0.25)
                                                    }
                                                />
                                            </div>
                                            <div
                                                className={`swipe--carousel swipe--carousel-top swipe--carousel-BOTTOM`}
                                                style={{
                                                    top: `${
                                                        (company[`swipe_bottom_ratio`]?.top ||
                                                            0.55) * 100
                                                    }%`,
                                                }}
                                            >
                                                <CarouselGarment
                                                    type={BOTTOM}
                                                    cardHeight={
                                                        swipeHeight *
                                                        (company[`swipe_bottom_ratio`]?.height ||
                                                            0.3)
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                    {isTypeAsDress(garmentType) && (
                                        <div
                                            className={`swipe--carousel swipe--carousel-top swipe--carousel-${garmentType}`}
                                            style={{
                                                top: `${
                                                    (company[
                                                        `swipe_${garmentType.toLowerCase()}_ratio`
                                                    ]?.top || 0.25) * 100
                                                }%`,
                                            }}
                                        >
                                            <CarouselGarment
                                                type={garmentType}
                                                cardHeight={
                                                    swipeHeight *
                                                    (company[
                                                        `swipe_${garmentType.toLowerCase()}_ratio`
                                                    ]?.height ||
                                                        company[`swipe_dress_ratio`]?.height ||
                                                        0.25)
                                                }
                                            />
                                        </div>
                                    )}
                                    {garmentType === OUTERWEAR && (
                                        <div
                                            className={`swipe--carousel swipe--carousel-top swipe--carousel-${garmentType}`}
                                            style={{
                                                top: `${
                                                    (company[`swipe_outerwear_ratio`]?.top ||
                                                        0.25) * 100
                                                }%`,
                                            }}
                                        >
                                            <CarouselGarment
                                                type={OUTERWEAR}
                                                cardHeight={
                                                    swipeHeight *
                                                    (company[`swipe_outerwear_ratio`]?.height ||
                                                        0.25)
                                                }
                                            />
                                        </div>
                                    )}

                                    {/* DEFAULT CASE FOR GARMENT TYPE CAROUSEL */}
                                    {[TOP, BOTTOM, OUTERWEAR].indexOf(garmentType) === -1 &&
                                        !isTypeAsDress(garmentType) && (
                                            <div
                                                className={`swipe--carousel swipe--carousel-top swipe--carousel-${garmentType}`}
                                                style={{
                                                    top: `${
                                                        (company[
                                                            `swipe_${garmentType.toLowerCase()}_ratio`
                                                        ]?.top || 0.25) * 100
                                                    }%`,
                                                }}
                                            >
                                                <CarouselGarment
                                                    type={garmentType}
                                                    cardHeight={
                                                        swipeHeight *
                                                        (company[
                                                            `swipe_${garmentType.toLowerCase()}_ratio`
                                                        ]?.height || 0.25)
                                                    }
                                                />
                                            </div>
                                        )}
                                </>
                            )}
                        </>
                    )}
                    <div className='card-model--bottom-right'>
                        {(layoutSlice.rightPart === 'none' ||
                            window.innerWidth / window.innerHeight < 1) &&
                            !hideRightPart && (
                                <Button
                                    className='button--shop'
                                    onClick={handleBuyClick}
                                    type='primary'
                                >
                                    {t('layout.shop')}
                                </Button>
                            )}
                    </div>
                    <div
                        className={`card-model--bottom-left${
                            isSlideOverlapBottom ? ' card-model--bottom-left-blur' : ''
                        }`}
                    >
                        {company.enable_tuck && !tuckDisabled && (
                            <Row
                                align='middle'
                                style={{ gap: '8px' }}
                                className='card-model--checkbox-container'
                            >
                                <Checkbox
                                    checked={look?.mode === 'tuck'}
                                    onChange={handleChangeTuck}
                                    disabled={tuckDisabled}
                                >
                                    <span
                                        className={`card-model--checkbox-label ${
                                            tuckDisabled
                                                ? 'card-model--checkbox-label-disabled'
                                                : ''
                                        }`}
                                    >
                                        {t('look.tuck_in')}
                                    </span>
                                </Checkbox>
                            </Row>
                        )}
                        {look?.image_urls.length > 1 && handleBackClick && (
                            <Row
                                align='middle'
                                style={{ gap: '8px' }}
                                className='card-model--checkbox-container'
                            >
                                <Checkbox
                                    checked={lookIndex === 1}
                                    onChange={() => handleBackClick(lookIndex === 0 ? 1 : 0, null)}
                                >
                                    <span className={`card-model--checkbox-label`}>
                                        {t('look.back')}
                                    </span>
                                </Checkbox>
                            </Row>
                        )}
                    </div>
                    <div className='card-model--top-right'>
                        <FavoriteButton
                            data={look}
                            textLeft={t('favorite.save_outfit')}
                            eventCategory='Outfit'
                        />
                        {look && withInfo && (
                            <Tooltip
                                trigger={['click', 'hover']}
                                placement='left'
                                overlayClassName='card-model card-model--tooltip'
                                title={
                                    <div style={{ textAlign: 'left' }}>
                                        {info}
                                        {look.are_pants_trimmed && (
                                            <>
                                                <br />
                                                <br />
                                                {t('look.pants_trim_info')}
                                            </>
                                        )}
                                        <br />
                                        <br />
                                        {t('look.info')}
                                        <br />
                                        <br />
                                        <PoweredBy alwaysShow onClick={handlePoweredClick} />
                                    </div>
                                }
                            >
                                <Button
                                    icon={getInfoIcon()}
                                    type='text'
                                    className='button button--icon-right'
                                ></Button>
                            </Tooltip>
                        )}

                        <Button
                            icon={getZoomIcon()}
                            type='text'
                            className='button button--icon-right button--look-zoom'
                            onClick={handleZoomClick}
                        />
                    </div>
                    <div className='card-model--top-left'>
                        {company.enable_full_window && (
                            <Button
                                icon={headerHidden ? getContractIcon() : getExpandIcon()}
                                type='text'
                                className='button button--icon button--full-window'
                                onClick={() =>
                                    showHideHeader(
                                        headerHidden,
                                        'button--full-window',
                                        undefined,
                                        'Outfit'
                                    )
                                }
                            >
                                {window.innerWidth / window.innerHeight >= 1 &&
                                    (headerHidden
                                        ? t('layout.redure_window')
                                        : t('layout.full_window'))}
                            </Button>
                        )}
                    </div>
                </div>
            )}
            {look && (
                <>
                    <LookContainer noLoader={carouselLoading} />
                    <Lightbox
                        open={zoomVisible}
                        close={() => setZoomVisible(false)}
                        slides={imagesSlides}
                        index={lookIndex}
                        carousel={{ finite: imagesSlides.length === 1 }}
                        render={
                            imagesSlides.length === 1 && {
                                buttonNext: () => null,
                                buttonPrev: () => null,
                            }
                        }
                        on={{
                            entered: handleOpenZoom,
                        }}
                        styles={{ container: { backgroundColor: '#000A' } }}
                        plugins={[Zoom as Plugin]}
                        zoom={{
                            ref: zoomRef,
                            maxZoomPixelRatio: 3,
                            zoomInMultiplier: 2,
                        }}
                    />
                </>
            )}
        </>
    )
}

export default SwipePage
