import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'

import CardItem from '../../components/card/cardItem'
import {
    getAllGarmentsForType,
    getCurrentActiveFiltersForType,
} from 'src/store/slices/databaseSlice'
import { useAppSelector } from 'src/store'
import { Card, Col } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { forceCheck } from 'react-lazyload'
import { removeVariantFilter } from 'src/utils/variantHelpers'
import { trackEvent } from 'src/utils/tracking'
import useTypeFunctions from 'src/utils/typeMethods-hook'
import Loader from 'src/components/Loader'
import useCustomGetGarments from 'src/utils/custom-getGarments-hook'

const Catalog: React.FunctionComponent = () => {
    const { t } = useCustomTranslation()
    const ref = useRef<HTMLDivElement>()
    const { isPrimaryOrSecondaryType } = useTypeFunctions()

    const garmentType = useSelector((state: State.Root) => state.garment?.type)
    const allGarments = useAppSelector((state) => getAllGarmentsForType(state, garmentType))
    const company = useSelector((state: State.Root) => state.profile?.company)
    const lookRequest = useAppSelector((state) => state.look.request)
    const activeFilters = useAppSelector((state) =>
        getCurrentActiveFiltersForType(state, garmentType)
    )

    const [, { isLoading }] = useCustomGetGarments()

    const garmentImgRatio = 100 / (company?.garment_image_ratio || 0.66)

    useEffect(() => {
        const lookGarment = lookRequest[garmentType.toLowerCase()]
        if (!lookGarment) {
            return
        }

        const element = document.getElementById(lookGarment.garment_id)
        if (!element) {
            return
        }

        element.scrollIntoView()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [garmentType])

    // ---- FIX workaround for image not lazyloaded when we open the drawer
    useEffect(() => {
        setTimeout(() => {
            forceCheck()
        }, 500)
    }, [])

    const handleVariantBackClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        trackEvent(
            'Variant Back Clicked',
            {
                look: lookRequest,
                show_type: garmentType,
                show_value: lookRequest[garmentType] ? 'off' : 'on',
            },
            'Catalog'
        )
        removeVariantFilter()
    }

    if (!allGarments || company.garment_types.indexOf(garmentType) === -1) {
        return (
            <div
                className='catalog catalog--container'
                ref={ref}
                style={{ height: '100%', alignItems: 'center' }}
            >
                {isLoading && <Loader />}
            </div>
        )
    }

    if (allGarments?.length === 0 && !isLoading) {
        return (
            <div className='catalog catalog--container' ref={ref}>
                <div className='catalog--empty'>{t('product.no_products')}</div>
            </div>
        )
    }

    return (
        <>
            <div
                className={`catalog catalog--container override_catalog_container${
                    !isPrimaryOrSecondaryType(garmentType) ||
                    lookRequest?.optional_types?.includes(garmentType)
                        ? ' catalog--container--with-remove'
                        : ''
                }`}
                ref={ref}
            >
                {Object.keys(activeFilters).includes('garment_variant_ids') && (
                    <Card
                        hoverable
                        className={
                            'card card--container override_card_container override_card_catalog_container'
                        }
                        style={{ height: '100%' }}
                        cover={
                            <ArrowLeftOutlined
                                style={{
                                    opacity: 0.7,
                                    color: 'gray',
                                    fontSize: 100,
                                    minHeight: 200,
                                    justifyContent: 'center',
                                }}
                            />
                        }
                        onClick={handleVariantBackClick}
                    >
                        <div className='catalog--remove-text'>{t('product.return_list')}</div>
                    </Card>
                )}

                {allGarments.map((item: Models.Garment) => (
                    <Col
                        className='catalog--item gutter-row override_catalog_grid_column'
                        key={item.garment_id}
                    >
                        <CardItem data={item} ratio={garmentImgRatio} />
                    </Col>
                ))}
            </div>
            {isLoading && (
                <Col
                    className='catalog--item gutter-row override_catalog_grid_column'
                    style={{ minHeight: 100 }}
                >
                    <Loader ignoreCustom />
                </Col>
            )}
        </>
    )
}

export default Catalog
