import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'

import CardFavorites from '../../components/card/cardFavorites'

import { SwitchFavoritesContentAction } from 'src/store/actions/favorites'
import { Col } from 'antd'
import { usePostPricesMutation } from 'src/store/api/api-price'

const FavoritesCatalog: React.FunctionComponent = () => {
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const [postPrices] = usePostPricesMutation()

    const favorites = useSelector((state: State.Root) => state.favorites)
    const company = useSelector((state: State.Root) => state.profile?.company)

    const [convertedPrices, setConvertedPrices] = useState<Models.Price[]>()

    const list = favorites.isOutfit ? favorites.looks : favorites.garments

    useEffect(() => {
        if (!favorites.isOutfit && !favorites.garments.length && favorites.looks.length) {
            dispatch(SwitchFavoritesContentAction(true))
        } else if (favorites.isOutfit && !favorites.looks.length && favorites.garments.length) {
            dispatch(SwitchFavoritesContentAction(false))
        }
        // eslint-disable-next-line
    }, [])

    // ---- Get Converted Prices Use Effect ----
    useEffect(() => {
        if (favorites.isOutfit || !company.enable_prices_api) {
            return
        }

        postPrices({ garment_ids: list.map((garment) => garment.garment_id) })
            .unwrap()
            .then((resp) => {
                setConvertedPrices(resp)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list])

    const imgRatio =
        100 /
        ((favorites.isOutfit ? company?.look_image_ratio : company?.garment_image_ratio) || 0.66)

    return (
        <div
            className={`catalog catalog--favorites--container ${
                favorites.isOutfit ? 'catalog--outfit' : ''
            }`}
            style={
                list && list.length === 0
                    ? { justifyContent: 'center', height: '100%', gridTemplateColumns: 'none' }
                    : {}
            }
        >
            {list &&
                list.length > 0 &&
                list.map((item: any, itemKey: number) => (
                    <Col
                        className='catalog--item-favorites gutter-row override_catalog_grid_column'
                        key={item.look_id || item.garment_id || itemKey}
                    >
                        <CardFavorites
                            data={item}
                            ratio={imgRatio}
                            isOutfit={favorites.isOutfit}
                            convertedPrice={convertedPrices?.find(
                                (price) => price.garment_id === item.garment_id
                            )}
                        />
                    </Col>
                ))}
            {list && list.length === 0 && (
                <div className='catalog--empty'>
                    {t(
                        favorites.isOutfit
                            ? 'favorite.no_favorite_outfits'
                            : 'favorite.no_favorite_items'
                    )}
                </div>
            )}
        </div>
    )
}

export default FavoritesCatalog
