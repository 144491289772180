import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useAppSelector } from 'src/store'
import { resizeImage } from 'src/utils/image'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'
import useTypeClick from 'src/utils/typeClick-hook'
import StyleBarTile from './StyleBarTile'
import { useDispatch } from 'react-redux'
import {
    closeDrawer,
    closeLeftPart,
    expandDrawer,
    expandLeftPart,
    expandRightPart,
    shrinkDrawer,
    shrinkLeftPart,
} from 'src/store/slices/layoutSlice'
import { DownOutlined, LeftOutlined, RightOutlined, UpOutlined } from '@ant-design/icons'
import { handleCatalogScroll } from 'src/utils/scroll'
import { Button, Drawer, Row } from 'antd'
import SubHeader from '../header/SubHeader'
import { useFiltersContext } from '../LocalFiltersContext'
import FilterButton from '../button/FilterButton'
import { ChangeShowFiltersAction } from 'src/store/actions/filters'
import { countActiveFilters } from 'src/store/slices/databaseSlice'
import { getCustomMinusIcon, getDrawerDownIcon, getDrawerUpIcon } from 'src/utils/icon'
import useTypeFunctions from 'src/utils/typeMethods-hook'
import { removeGarment } from 'src/utils/garment'
import CloseButton from '../button/CloseButton'
import CatalogHorizontal from 'src/containers/catalog/CatalogHorizontal'
import Catalog from 'src/containers/catalog/Catalog'
import ItemCounter from '../ItemCounter'
import ModelTile from './ModelTile'

const HORIZONTAL_CATALOG_HEIGHT = 185
const HORIZONTAL_CATALOG_WITH_NAME_HEIGHT = 265

interface StyleBarProps {
    height?: number
}

export default function StyleBar(props: StyleBarProps) {
    const { t } = useCustomTranslation()
    const { styleBarTypeClick } = useTypeClick()
    const dispatch = useDispatch()
    const FiltersContext = useFiltersContext()
    const { isPrimaryOrSecondaryType } = useTypeFunctions()

    const garmentTypes = useAppSelector((state) => state.profile.company.garment_types)
    const company = useAppSelector((state) => state.profile.company)
    const garmentType = useAppSelector((state) => state.garment.type)
    const lookRequest = useAppSelector((state) => state.look.request)
    const garmentsHistory = useAppSelector((state) => state.look.garmentsHistory)
    const layoutSlice = useAppSelector((state) => state.layoutSlice)
    const showFilters = useAppSelector((state) => state.filters.showFilters)
    const numberActiveFilters = useAppSelector((state) => countActiveFilters(state))

    const [styleScrollTop, setStyleScrollTop] = useState<number>(0)
    const [styleScrollLeft, setStyleScrollLeft] = useState<number>(0)
    const [styleClientWidth, setStyleClientWidth] = useState<number>(0)
    const [styleScrollWidth, setStyleScrollWidth] = useState<number>(0)
    const [styleScrollHeight, setStyleScrollHeight] = useState<number>(0)
    const [styleClientHeight, setStyleClientHeight] = useState<number>(0)

    const styleBarRef = useRef<HTMLDivElement>()

    const { height } = props

    const handleGarmentClick = (e: React.MouseEvent, type: string, iconClick?: boolean) => {
        e.stopPropagation()
        if (!iconClick) {
            trackEvent('Type Clicked', { catalog_type: type }, 'Style Bar')
        }

        // ---- We don't handle the click when there is not the garmentsHistory value for this type ----
        if (!garmentsHistory[type]) {
            return
        }

        styleBarTypeClick(type, iconClick)

        // ---- We don't handle the drawers if we clicked the checkbox ----
        if (iconClick) {
            return
        }

        if (window.innerWidth / window.innerHeight >= 1) {
            // ---- If it's closed we open the left part ----
            if (layoutSlice.leftPart === 'none') {
                trackEvent(
                    'Expand Clicked',
                    { expand_from: layoutSlice.leftPart, expand_type: 'left' },
                    'Style Bar'
                )
                dispatch(expandLeftPart())
                return
            }

            /**
             * If it's already openned and we click on the same garment type
             * AND we have the current garmentType on the look we close it
             */
            if (garmentType === type && lookRequest[garmentType.toLowerCase()]) {
                trackEvent(
                    'Shrink Clicked',
                    { shrink_from: layoutSlice.leftPart, expand_type: 'left' },
                    'Style Bar'
                )
                dispatch(closeLeftPart())
            }
        } else {
            trackEvent(
                'Expand Clicked',
                { expand_from: layoutSlice.drawer, expand_type: 'drawer' },
                'Style Bar'
            )
            dispatch(expandDrawer())
        }
    }

    const handleCheckboxClick = (e: React.MouseEvent, type: string) => {
        e.stopPropagation()

        trackEvent(
            'Show Type Changed',
            {
                look: lookRequest,
                show_type: type,
                show_value: lookRequest[type.toLowerCase()] ? 'off' : 'on',
            },
            'Style Bar'
        )

        if (!lookRequest || !lookRequest[type.toLowerCase()]) {
            handleGarmentClick(e, type, true)
            return
        }

        removeGarment(type)
    }

    const handleFilterClick = (e, path = null) => {
        e.preventDefault()
        if (path === '/model') {
            trackEvent('Filter Clicked', { filter_type: 'model' }, 'Menu')
        } else {
            trackEvent('Filter Clicked', { catalog_type: garmentType, filter_type: 'item' }, 'Menu')
        }

        // ---- Expand the right part if it was closed so we see the filters ----
        if (!showFilters && layoutSlice.rightPart === 'none') {
            dispatch(expandRightPart())
        }
        dispatch(ChangeShowFiltersAction(!showFilters))
    }

    const handleExpandCatalog = () => {
        if (window.innerWidth / window.innerHeight < 1) {
            trackEvent(
                'Expand Clicked',
                { expand_from: layoutSlice.drawer, expand_type: 'drawer' },
                'Style Bar'
            )
            dispatch(expandDrawer())
            return
        }
        trackEvent(
            'Expand Clicked',
            { expand_from: layoutSlice.leftPart, shrink_type: 'left' },
            'Style Bar'
        )

        // ---- Close filters if we set the left part to big ----
        if (showFilters && layoutSlice.leftPart === 'medium') {
            dispatch(ChangeShowFiltersAction(!showFilters))
        }
        dispatch(expandLeftPart())
    }

    const handleShrinkCatalog = () => {
        if (window.innerWidth / window.innerHeight < 1) {
            trackEvent(
                'Shrink Clicked',
                { shrink_from: layoutSlice.drawer, shrink_type: 'drawer' },
                'Style Bar'
            )
            dispatch(shrinkDrawer())
            return
        }

        trackEvent(
            'Shrink Clicked',
            { shrink_from: layoutSlice.leftPart, shrink_type: 'left' },
            'Style Bar'
        )
        dispatch(shrinkLeftPart())
    }

    const handleCloseCatalog = () => {
        if (window.innerWidth / window.innerHeight < 1) {
            trackEvent(
                'Close Clicked',
                { close_from: layoutSlice.drawer, close_type: 'drawer' },
                'Style Bar'
            )
            dispatch(closeDrawer())
            return
        }
        trackEvent(
            'Close Clicked',
            { close_from: layoutSlice.leftPart, close_type: 'left' },
            'Style Bar'
        )

        // ---- Close filters if open when we close the catalog ----
        if (showFilters) {
            dispatch(ChangeShowFiltersAction(!showFilters))
        }

        dispatch(closeLeftPart())
    }

    // Store the different content width values in state so it update the check on the arrows condition
    const updateStylBarValues = (content: HTMLElement) => {
        setStyleScrollLeft(Math.ceil(content.scrollLeft))
        setStyleScrollWidth(content.scrollWidth)
        setStyleClientWidth(content.clientWidth)
        setStyleScrollTop(content.scrollTop)
        setStyleScrollHeight(content.scrollHeight)
        setStyleClientHeight(content.clientHeight)
    }

    const onLeftArrowClick = () => {
        styleBarRef?.current.scrollTo({
            left:
                styleScrollLeft < styleBarRef.current.clientWidth
                    ? 0
                    : styleScrollLeft - styleBarRef.current.clientWidth,
            behavior: 'smooth',
        })
    }

    const onRightArrowClick = () => {
        styleBarRef?.current.scrollTo({
            left:
                styleScrollLeft > styleScrollWidth - styleClientWidth
                    ? styleScrollWidth
                    : styleScrollLeft + styleClientWidth,
            behavior: 'smooth',
        })
    }

    const handleRemoveClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        trackEvent(
            'Show Type Changed',
            {
                look: lookRequest,
                show_type: garmentType,
                show_value: lookRequest[garmentType] ? 'off' : 'on',
            },
            'Catalog'
        )
        removeGarment(garmentType)
    }

    const onUpArrowClick = () => {
        styleBarRef?.current.scrollTo({
            top:
                styleScrollTop < styleBarRef.current.clientHeight
                    ? 0
                    : styleScrollTop - styleBarRef.current.clientHeight,
            behavior: 'smooth',
        })
    }

    const onDownArrowClick = () => {
        styleBarRef?.current.scrollTo({
            top:
                styleScrollTop > styleScrollHeight - styleClientHeight
                    ? styleScrollHeight
                    : styleScrollTop + styleClientHeight,
            behavior: 'smooth',
        })
    }

    const showSubHeader = useMemo<boolean>(
        () =>
            company.garment_category_facets !== null &&
            company.garment_category_facets !== undefined &&
            company.garment_category_facets[garmentType] !== null,
        [company, garmentType]
    )

    const canRemove = useMemo<boolean>(
        () =>
            !isPrimaryOrSecondaryType(garmentType) ||
            lookRequest?.optional_types?.includes(garmentType),
        [garmentType, lookRequest, isPrimaryOrSecondaryType]
    )

    useEffect(() => {
        if (!styleBarRef.current) {
            return undefined
        }

        // ---- Direct update when we have the stylebarelement ----
        updateStylBarValues(styleBarRef.current)

        let interval = null
        const content = styleBarRef.current

        // Handle Resize of the window
        function handleResize() {
            if (content) {
                clearInterval(interval)
                interval = null
                updateStylBarValues(content)
            }
        }

        interval = setInterval(() => {
            handleResize()
        }, 100)
        window.addEventListener('resize', handleResize)
        return () => {
            if (interval) {
                clearInterval(interval)
            }

            window.removeEventListener('resize', handleResize)
        }
    }, [styleBarRef])

    return (
        <>
            <Drawer
                destroyOnClose={true}
                placement='bottom'
                open={layoutSlice.drawer !== 'none' && window.innerWidth / window.innerHeight < 1}
                onClose={handleCloseCatalog}
                height={
                    layoutSlice.drawer === 'big'
                        ? '90%'
                        : company.name_horizontal_catalog
                        ? HORIZONTAL_CATALOG_WITH_NAME_HEIGHT
                        : HORIZONTAL_CATALOG_HEIGHT
                }
                mask={layoutSlice.drawer === 'big'}
                closable={false}
                rootClassName={`drawer--catalog ${
                    canRemove && layoutSlice.drawer === 'big' ? ' drawer--catalog-remove' : ''
                } drawer--catalog--layout-${layoutSlice.drawer}`}
                title={
                    <div
                        className={`drawer--header${
                            !showSubHeader ? ' drawer--header-nosubheader' : ''
                        }`}
                    >
                        {showSubHeader && FiltersContext && (
                            <SubHeader
                                localFilters={FiltersContext.localFilters}
                                onClick={FiltersContext.onSubHeaderClick}
                                onlyOneSubCategory={FiltersContext.onlyOneSubCategory}
                            />
                        )}
                        <Row wrap={false} className='drawer--header-right' justify='space-between'>
                            {showSubHeader && (
                                <FilterButton
                                    onClick={(e) => handleFilterClick(e, '/catalog')}
                                    countFilter={numberActiveFilters}
                                />
                            )}
                            <CloseButton
                                onClick={handleCloseCatalog}
                                className='drawer--header-close'
                            />
                        </Row>
                    </div>
                }
            >
                <div
                    style={{
                        overflowY: layoutSlice.drawer === 'small' ? 'unset' : 'auto',
                        overflowX: layoutSlice.drawer === 'small' ? 'auto' : 'unset',
                    }}
                    className='drawer--content'
                    onScroll={handleCatalogScroll}
                >
                    {layoutSlice.drawer === 'big' && (
                        <>
                            {canRemove && (
                                <div className='drawer--remove-container'>
                                    <Button
                                        onClick={handleRemoveClick}
                                        className='button--outlined button--remove'
                                        size='large'
                                        icon={getCustomMinusIcon()}
                                    >
                                        {t('product.remove')}
                                    </Button>
                                </div>
                            )}
                            <ItemCounter />
                        </>
                    )}
                    <div className='drawer--toggle-container'>
                        <Button
                            icon={getDrawerUpIcon()}
                            onClick={handleExpandCatalog}
                            className={`button--toggle-size ${
                                layoutSlice.drawer === 'big' && 'button--toggle-size-disabled'
                            } button--toggle-size-open`}
                            type='link'
                        />
                        <Button
                            icon={getDrawerDownIcon()}
                            onClick={handleShrinkCatalog}
                            className='button--toggle-size button--toggle-size-close'
                            type='link'
                        />
                    </div>
                    {layoutSlice.drawer === 'small' && (
                        <CatalogHorizontal
                            height={
                                company.name_horizontal_catalog
                                    ? HORIZONTAL_CATALOG_WITH_NAME_HEIGHT
                                    : HORIZONTAL_CATALOG_HEIGHT
                            }
                        />
                    )}
                    {layoutSlice.drawer === 'big' && <Catalog />}
                </div>
            </Drawer>

            <div
                style={{ height }}
                className={`style-bar--container`}
                id='StyleBarContainerId'
                ref={styleBarRef}
                onScroll={(e) => updateStylBarValues(e.currentTarget)}
            >
                {styleScrollLeft !== 0 && (
                    <div className='style-bar--arrow-container-left' onClick={onLeftArrowClick}>
                        <LeftOutlined />
                    </div>
                )}
                {styleScrollTop !== 0 && (
                    <div className='style-bar--arrow-container-up' onClick={onUpArrowClick}>
                        <UpOutlined />
                    </div>
                )}
                {garmentsHistory && (
                    <>
                        <ModelTile />

                        {garmentTypes.map((type) => (
                            <StyleBarTile
                                key={type}
                                image={resizeImage(
                                    garmentsHistory[type]?.image_clipping_url ||
                                        garmentsHistory[type]?.image_url,
                                    {
                                        width: 800,
                                    }
                                )}
                                title={t(`layout.see_${type.toLowerCase()}`)}
                                onClick={(e) => handleGarmentClick(e, type)}
                                hidden={!lookRequest || !lookRequest[type.toLowerCase()]}
                                noIcon={isPrimaryOrSecondaryType(type)}
                                checkboxClick={(e) => handleCheckboxClick(e, type)}
                                overrideClassName={`style-bar-${type}`}
                            />
                        ))}
                    </>
                )}
                {styleScrollLeft + styleClientWidth < styleScrollWidth && (
                    <div className='style-bar--arrow-container-right' onClick={onRightArrowClick}>
                        <RightOutlined />
                    </div>
                )}
                {styleScrollTop + styleClientHeight < styleScrollHeight && (
                    <div className='style-bar--arrow-container-down' onClick={onDownArrowClick}>
                        <DownOutlined />
                    </div>
                )}
            </div>
        </>
    )
}
